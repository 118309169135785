import {React, useState, useContext} from 'react';

import '../css/Line.css';
import {Row} from "react-bootstrap";
import ColContent from "./ColContent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGripLines, faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import {RawContext} from "./context/RawContext";
import Line from "./menu/Line";
import {ContentContext} from "./context/ContentContext";
import {PageContext} from "./context/PageContext";

function LineContent({line = undefined, index, handleRemove, content = undefined}) {
    const { setDisplayDrawer, setContextDrawer, setContextName } = useContext(ContentContext)
    const [showMenu, setShowMenu] = useState(true);
    const { displayRaw} = useContext(RawContext)
    const { removeLine } = useContext(PageContext)

    const editLineMenu = (line) => {
        setDisplayDrawer(true)
        setContextName(<><FontAwesomeIcon icon={faGripLines} />  Ligne</>)
        setContextDrawer(<Line lineId={line.id} />)
    }

    const editLine = () => {
        handleRemove(index)
    } //line-editable

    return (
        <Row className={"position-relative ".concat((!displayRaw) ? "line-editable" : "")} onMouseOver={() => setShowMenu(true)} onMouseLeave={() => setShowMenu(false)}>
            { (showMenu && !displayRaw) &&
                <div style={{zIndex: "1500", width:"30px", right: "0", bottom: "0"}} className={"d-flex justify-content-end position-absolute"}>
                    <FontAwesomeIcon style={{fontSize: "20px", borderRadius: "2px"}} onClick={() => editLineMenu(line)} className={"button-line"} icon={faPenToSquare} />&nbsp;
                    <FontAwesomeIcon style={{fontSize: "20px", borderRadius: "2px"}} onClick={() => removeLine(line)} className={"button-line"} icon={faTrash} />
                    {/*<div className={"ms-1"}><FontAwesomeIcon className={"button-col"} icon={faPenToSquare} /></div>*/}
                </div>
            }
            {line.cols.map((col, i) =>
                <ColContent
                    first={i === 0}
                    key={col.id}
                    lineId={line.id}
                    col={col}
                />
            )}
        </Row>
    );
}

export default LineContent