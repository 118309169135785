import {useState, createContext} from "react";
import {Alert, Snackbar} from "@mui/material";

export const AlertContext = createContext()

export const AlertProvider = ({ children }) => {
    const [message, setMessage] = useState("Votre page a été sauvegardée");
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const vertical = "top"
    const horizontal = "center"

    return (
        <AlertContext.Provider value={{ setSeverity, setOpen, setMessage }}>
            {children}
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={() => handleClose()} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </AlertContext.Provider>
    )
}