export function getCivility(value) {
    if (value === "Male") {
        return "M."
    } else {
        return "Mme"
    }
}

export function getCourseType(value) {
    if (value === "private-lesson") {
        return "Cours particulier"
    } else {
        return "Atelier"
    }
}