import {UserContext} from "./context/UserContext";
import {React, useContext} from 'react';
import { Navigate, Outlet } from 'react-router-dom';

function RequireAuth() {
    const { user, getUser, isConnected } = useContext(UserContext)

    if (!isConnected() || getUser === null) {
        return <Navigate to="/login" />;
    }

    return <Outlet />;
}

export default RequireAuth
