import React from 'react';
import {useContext, useEffect, useState} from "react";
import '../css/RowTemplate.css';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import { teal } from '@mui/material/colors';
import {FamilyContext} from "./context/FamilyContext";
import {PageContext} from "./context/PageContext";
import PageWrapper from "./PageWrapper";
import {
    Alert,
    Box, Divider,
    FormControl,
    Link,
    Paper,
    Stack,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { styled } from '@mui/material/styles';
import Cm2cButton, {Cm2cLoadingButton} from "./Cm2cButton";
import {isEmail, isPhone} from "./Helper/Validator";
import Students from "./subscription/Students";
import Courses from "./subscription/Courses";
import Summary from "./subscription/Summary";
import Completed from "./subscription/Completed";
import Family from "./subscription/Family";
import {UserContext} from "./context/UserContext";
import {fetchApiPlatform, postApi, publicFetchApiPlatform, publicPostApi} from "./api/Content";
import {CourseContext} from "./context/CourseContext";
import {AlertContext} from "./context/AlertContext";
const steps = ['Famille', 'Éleve(s)', 'Cours', 'Récapitulatif'];

function ValidateEmail() {
    const { setMessage, setOpen, setSeverity } = useContext(AlertContext)
    const [valid, setValid] = useState(false);
    const [error, setError] = useState(false);
    const [repeatedPassword, setRepeatedPassword] = useState("");

    const { family, loadFamily } = useContext(FamilyContext)
    const { getUser } = useContext(UserContext)
    const [params, setParams] = useSearchParams();

    useEffect(() => {
        if (params.get("token") === null) {
            window.location.href = "/"
        }
        publicFetchApiPlatform("/subscription/validate/email?token=" + params.get("token")).then(response => {
            if (response.ok) {
                setValid(true)
            }
        }).catch((e) => {
            setValid(false)
            setError(true)
        });
    }, [])

    return (
        <PageWrapper>
            <Grid2 container spacing={2} >
                <Grid2 md={6} xlOffset={4} xl={4} >
                    <Paper elevation={2}>
                        <Grid2 container
                               p={2}
                               direction="row"
                               display="flex"
                               justifyContent="center">
                            <Grid2  textAlign={"center"} xs={12}>
                                <Typography variant={"h6"}>Vérifiaction de votre email</Typography>
                            </Grid2>
                            <Grid2  textAlign={"center"} xs={12}>
                                <Divider />
                            </Grid2>

                            <Grid2  mt={2}  textAlign={"center"} xs={12}>
                                { error &&
                                    <Alert severity="error">
                                        Une erreur est survenue
                                    </Alert>
                                }
                                { !error && (
                                     valid ?
                                        <Alert severity="success">
                                            Votre email à été vérifié, merci pour votre confiance.<br/>
                                            L'équipe du centre musical de Chalamont est ravie de vous compter parmis ses membres !
                                        </Alert>

                                        :
                                        <Alert severity="warning">Veuillez patienter, votre email est en cours de vérification</Alert>
                                )}

                            </Grid2>
                            <Grid2 textAlign={"center"} xs={12} mt={2}>
                                <Link href={"/login"}>
                                    <Cm2cButton disabled={false} >Se connecter</Cm2cButton>
                                </Link>
                            </Grid2>
                        </Grid2>
                    </Paper>
                </Grid2>
            </Grid2>
        </PageWrapper>
    );
}

export default ValidateEmail