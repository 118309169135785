import './App.css';
import Templator from "./components/Templator";
import {ContentProvider} from "./components/context/ContentContext";
import {RawProvider} from "./components/context/RawContext";
import {PageProvider} from "./components/context/PageContext";
import {useEffect} from "react";

import {
    createBrowserRouter,
    RouterProvider,
    BrowserRouter,
    Routes, Route, Router
} from "react-router-dom";
import Pager from "./components/Pager";
import {UserProvider} from "./components/context/UserContext";
import Login from "./components/Login";
import {AlertProvider} from "./components/context/AlertContext";
import Events from "./components/Events";
import Event from "./components/Event";
import Page from "./components/Page";
import {MenuProvider} from "./components/context/MenuContext";
import RequireAuth from "./components/RequiredAuth";
import Subscription from "./components/Subscription";
import Home from "./components/Home";
import NotFound from "./components/NotFound";
import {FamilyProvider} from "./components/context/FamilyContext";
import {CourseProvider} from "./components/context/CourseContext";
import Profile from "./components/Profile";
import SubscriptionClosed from "./components/SubscriptionClosed";
import ResetPassword from "./components/ResetPassword";
import AskResetPassword from "./components/AskResetPassword";
import {SeasonProvider} from "./components/context/SeasonContext";
import ValidateEmail from "./components/ValidateEmail";


function App() {
    useEffect(() => {
        document.body.classList.add("back-"+ window.location.pathname.replace("/", ""))
    }, [])

  return (
      <>
        {/*<link*/}
        {/*    rel="stylesheet"*/}
        {/*    href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css"*/}
        {/*    integrity="sha384-Zenh87qX5JnK2Jl0vWa8Ck2rdkQ2Bzep5IDxbcnCeuOxjzrPF/et3URy9Bv1WTRi"*/}
        {/*    crossOrigin="anonymous"*/}
        {/*/>*/}
        <UserProvider>
            <SeasonProvider>
                <AlertProvider>
                    <MenuProvider>
                            <FamilyProvider>
                                <CourseProvider>
                                    <PageProvider>
                                        <ContentProvider>
                                            <RawProvider>
                                                <BrowserRouter>
                                                    <Routes>
                                                        <Route path="/" element={<Home />} />
                                                        <Route path="/:slug" element={<Page />} />
                                                        <Route path="/login" element={<Login />} />
                                                        <Route path="/inscription" element={<Subscription />} />
                                                        <Route path="/inscription-ferme" element={<SubscriptionClosed />} />
                                                        <Route path="/reset-password" element={<ResetPassword />} />
                                                        <Route path="/validate-email" element={<ValidateEmail />} />
                                                        <Route path="/reset-password/ask" element={<AskResetPassword />} />
                                                        <Route element={<RequireAuth />}>
                                                            <Route path="/profile/ma-reservation" element={<Profile />} />
                                                            <Route path="/pages" element={<Pager />} />
                                                            <Route path="/pages/:slug" element={<Templator />} />
                                                            <Route path="/pages/new" element={<Templator />} />
                                                        </Route>
                                                        <Route path="*" element={<NotFound />} />
                                                    </Routes>
                                                </BrowserRouter>
                                            </RawProvider>
                                        </ContentProvider>
                                    </PageProvider>
                                </CourseProvider>
                            </FamilyProvider>
                    </MenuProvider>
                </AlertProvider>
            </SeasonProvider>
        </UserProvider>
      </>
  );
}

export default App;
