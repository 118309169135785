import React from 'react';
import {useState, useEffect, useContext} from "react";
import {Col, Row, Container, Form, Button} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import LineSelector from "./LineSelector";
import {PageContext} from "../context/PageContext";

const CONFIGS = {
    1: [["12"]],
    2: [["9", "3"], ["8", "4"], ["7", "5"], ["6", "6"], ["5", "7"], ["4", "8"], ["3", "9"]],
    3: [["4", "4", "4"], ["3", "6", "3"], ["3", "3", "6"], ["6", "3", "3"]],
    4: [["3", "3", "3", "3"]],
}

function Line({line, lineId = undefined}) {
    const [columns, setColumns] = useState(2);
    const [currentLine, setCurrentLine] = useState(line);
    const [selectedTemplate, setSelectedTemplate] = useState([]);
    const { page, addLine, updateLine } = useContext(PageContext)

    useEffect(() => {
        setCurrentLine(page.template.find((line) => line.id === lineId));
    }, [])

    useEffect(() => {
        if (typeof currentLine !== "undefined") {
            let tmpSelTemplate = []
            currentLine.cols.map((col) => tmpSelTemplate.push(col.col));
            setSelectedTemplate(tmpSelTemplate)
        }
    }, [currentLine])

    const handleColumns = (e, value) => {
        setColumns(e.target.value)
    }

    useEffect(() => {
        if (typeof currentLine !== "undefined") {
            setColumns(currentLine.cols.length)
            let tmpSelTemplate = [];
            currentLine.cols.map((col) => tmpSelTemplate.push(col.col));
            setSelectedTemplate(tmpSelTemplate)
        }
    }, [])

    const handleLineColumns = (template) => {
        setSelectedTemplate(template)
    }

    const handleAddLine = () => {
        addLine(selectedTemplate);
    }

    useEffect(() => {
        if (typeof currentLine !== "undefined") {
            updateLine(currentLine, selectedTemplate);
        }
    }, [selectedTemplate])

    return (
        <Container>
            <Row>
                <Form.Group controlId="amount_columns">
                    <Form.Label>Nombre de colonnes</Form.Label>
                    <Form.Select onChange={handleColumns} value={columns} aria-label="Default select example">
                        <option key={1} value="1">Une</option>
                        <option key={2} value="2">Deux</option>
                        <option key={3} value="3">Trois</option>
                        <option key={4} value="4">Quatre</option>
                    </Form.Select>
                </Form.Group>
            </Row>
            <Container className={"mt-3"}>
                {CONFIGS[columns].map((template, index) =>
                <div className={"mt-2"}>
                    <LineSelector selected={selectedTemplate} handleClick={() => handleLineColumns(template)} key={index} configs={template} />
                </div>
                )}
            </Container>
            <Container className={"mt-2"}>
                <Row className="d-flex bd-highlight mb-3">
                    <Col className={"g-0"} style={{"textAlign" : "right"}}><Button size={"sm"} onClick={() => handleAddLine(selectedTemplate)} variant="success"><FontAwesomeIcon icon={faPlus} /> Ajouter une ligne</Button></Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Line