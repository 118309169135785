import React from 'react';
import {useState, useContext, useEffect} from "react";
import {Col} from "react-bootstrap";
import '../css/Col.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faPhotoVideo, faTrash} from "@fortawesome/free-solid-svg-icons";
import {ContentContext} from "./context/ContentContext";
import Content from "./menu/Content";
import Text from "./content/Text";
import ImageContent from "./content/ImageContent";
import {RawContext} from "./context/RawContext";
import ImageMenu from "./menu/ImageMenu";
import {PageContext} from "./context/PageContext";

function ColContent({first, col, lineId}) {
    const { setDisplayDrawer, setContextDrawer, setContextName } = useContext(ContentContext)
    const { displayRaw } = useContext(RawContext)
    const { preview } = useContext(PageContext)

    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        // const link = document.createElement('script');
        // link.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.6.3/jquery.min.js';
        // document.getElementsByTagName('head')[0].appendChild(link);
        // window.tata = function() {
        //     eval("$('.ck.ck-input.ck-input-text_empty.ck-input-text').each(function(i){ " +
        //         "if ($(this).next().html() == 'URL du lien' && $(this).next().next().attr('class') != 'lidjfgsdfg6sdf5g') {" +
        //         "$(this).parent().append('<select class=lidjfgsdfg6sdf5g><option>1</option><option>2</option></select>')" +
        //         "}" +
        //         " });");
        //     setTimeout(window.tata, 250);
        // };
        //window.tata();
    }, [])

    const editContent = () => {
        setDisplayDrawer(true)
        setContextName(<><FontAwesomeIcon icon={faPenToSquare} /> Contenu</>)
        setContextDrawer(<Content col={col} lineId={lineId} />)
    }

    return (
        <Col
            className={(preview ? "preview" : "") + " rounded-" + col.content.rounded +" p-" + col.content.padding +  " position-relative ".concat((!displayRaw) ? "col-editable" : "")} lg={col.col}
            onMouseOver={() => setShowMenu(true)}
            onMouseLeave={() => setShowMenu(false)}
            style={{backgroundColor: col.content.backgroundColor}}
        >
            { (showMenu && !displayRaw) &&
                <div style={{zIndex: "1500", left:0, bottom:0 }} className={"d-flex justify-content-end position-absolute"}>
                    <FontAwesomeIcon className={"button-col button-col mx-1"} style={{borderRadius: "2px"}}  onClick={editContent} icon={faPenToSquare} />
                    {/*{(currentCol.content.type === "image") && (<FontAwesomeIcon className={"button-col mx-1"} style={{fontSize: "20px", borderRadius: "2px"}} onClick={editMedia} icon={faPhotoVideo} />)}*/}
                    {/*<div className={"ms-1"}><FontAwesomeIcon className={"button-col"} icon={faPenToSquare} /></div>*/}
                </div>
            }
            {(col.content.type === "text") && (<Text lineId={lineId} col={col} />)}
            {(col.content.type === "image") && (<ImageContent lineId={lineId} col={col} />)}
        </Col>
    );
}

export default ColContent
