import React from 'react';
import {useState, useEffect} from "react";
import '../css/Pager.css';

import {fetchApiPlatform} from "./api/Content";
import MenuBar from "./MenuBar";
import CardPage from "./CardPage";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Button, Stack} from "@mui/material";
import {faFileCirclePlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Pager() {
    const [pages, setPages] = useState([]);

    useEffect(() => {
        fetchApiPlatform("/pages").then(data => {
            setPages(data["hydra:member"])
        })
    }, [])

    return (
        <>
            <MenuBar />
            <Grid2 mt={1} xsOffset={0} mdOffset={0} xlOffset={2} container spacing={2} xl={8} md={12} xs={12}>
                <Grid2 xs={12}>
                    <Stack direction="row" spacing={2}>
                        <Button href={"/pages/new"} variant="contained"><FontAwesomeIcon icon={faFileCirclePlus} />&nbsp;Créer une page</Button>
                    </Stack>
                </Grid2>
            </Grid2>
            <Grid2 mt={1} xsOffset={0} mdOffset={0} xlOffset={2} container spacing={2} xl={8} md={12} xs={12}>
                {
                    pages.map((page, i) => (
                        <CardPage page={page} key={page.id} />
                    ))
                }
            </Grid2>
        </>
    );
}

export default Pager