import {useState, createContext} from "react";
import ContextOffCanvas from "../ContextOffCanvas";

export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
    const [displayDrawer, setDisplayDrawer] = useState(false);
    const [contextDrawer, setContextDrawer] = useState(null);
    const [contextName, setContextName] = useState(null);

    return (
        <ContentContext.Provider value={{ displayDrawer, setDisplayDrawer, contextDrawer, setContextDrawer, contextName, setContextName }}>
            {children}
            <ContextOffCanvas handleClose={() => setDisplayDrawer(false)} name={contextName} displayDrawer={displayDrawer} >{contextDrawer}</ContextOffCanvas>
        </ContentContext.Provider>
    )
}