import React from 'react';
import {useContext, useEffect} from "react";
import '../css/Pager.css';

import {Container, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import LineContent from "./LineContent";
import {PageContext} from "./context/PageContext";
import {RawContext} from "./context/RawContext";
import PageWrapper from "./PageWrapper";
import {Box, Stack, Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import { ThemeOptions } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        h2: {
            fontSize: '2.1rem',
            fontFamily: "Ubuntu",
            fontWeight: 500,
            color: '#CC1408',
            marginBottom: '10px',
        },
        p: {
            fontSize: '1rem',
            letterSpacing: "0.03rem",
            lineHeight: "22px"
        },
        'p.lead': {
            fontSize: '1.25rem',
            fontWeight: 300,
        },
    },
});



function Home() {
    const { page, loadPage, setPreview } = useContext(PageContext)
    const { setDisplayRaw } = useContext(RawContext)
    let { slug } = useParams();

    useEffect(() => {
    }, [])

    return (
        <PageWrapper >
            <ThemeProvider theme={theme}>
                <Grid2 style={{backgroundColor: "white"}} p={2} container xl={8} xlOffset={2}>
                    <Grid2 xl={8}>
                        <Grid2 xs={12} >
                            <Typography variant={"h2"}>Bienvenue sur le site  des inscriptions du cm2c pour la saison 2023/2024 !</Typography>
                            <Typography variant={"p"}>
                                Le Centre Musical et Culturel de Chalamont (CM2C) est une association loi 1901. Il a pour but d'offrir un enseignement de qualité avec une équipe de professeurs diplômés. Il met en valeur les compétences et les envies de chacun, du cours individuel aux ateliers et grands ensembles que sont la batucada"Histoire de Pô" (percussions brésiliennes) et le big band jazz "Dim Dam Domb".
                                <br/><br/>   Vous pouvez sur ce site, consulter les différents cours disponibles, leurs prix,la liste des enseignents pour la saison...
                            </Typography>
                        </Grid2>
                        <Grid2 xs={12} >
                        </Grid2>
                    </Grid2>
                    <Grid2 xl={4}>
                        <Box
                            component={"img"}
                            sx={{
                                width: "100%",
                            }}
                            src={"https://dcfcn9te0xk7k.cloudfront.net/cm2c/cm2c-xs-64cf8ae34dd79.jpg"}
                        />
                        {/*<Stack direction="column" textAlign={"center"}>*/}
                        {/*    <Typography className={"lead"} component="p" variant="p">*/}
                        {/*        Centre Musical & Culturel<br/>*/}
                        {/*        107 rue des Garennes<br/>*/}
                        {/*        01320 Chalamont<br/><br/>*/}
                        {/*    </Typography>*/}
                        {/*    <Typography className={"lead"} component="p" variant="p">*/}
                        {/*        <a href="mailto:centremusicalchalamont@gmail.com">centremusicalchalamont@gmail.com</a><br/><br/>*/}
                        {/*    </Typography>*/}
                        {/*    <Typography className={"lead"} component="p" variant="p">*/}
                        {/*        <b>Coordination</b> :*/}
                        {/*        Nathalie TEPPE<br/>*/}
                        {/*        06 85 20 15 98*/}
                        {/*    </Typography>*/}
                        {/*</Stack>*/}
                    </Grid2>
                </Grid2>
            </ThemeProvider>
        </PageWrapper>
    );
}

export default Home