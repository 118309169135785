import React from 'react';
import {useState ,useContext, useEffect} from "react";
import {frFR} from '@mui/x-date-pickers/locales';
import Grid2 from "@mui/material/Unstable_Grid2";
import {PageContext} from "../context/PageContext";
import {
    Divider, FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText, InputLabel, MenuItem, Select,
    Stack,
    Switch,
    Link,
    TextField,
    Typography
} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {FamilyContext} from "../context/FamilyContext";
import {isEmail, isPhone} from "../Helper/Validator";
import {UserContext} from "../context/UserContext";
import dayjs from "dayjs";
import Cm2cButton from "../Cm2cButton";

function Family() {
    const [familyExist, setFamilyExist] = useState(false);

    const [phoneValid, setPhoneValid] = useState(true);
    const [disableUserExist, setDisableUserExist] = useState(false);

    const [emailValid, setEmailValid] = useState(true);
    const [emailError, setEmailError] = useState("");

    const { family, setFamily } = useContext(FamilyContext)
    const { signIn, getUser, isConnected } = useContext(UserContext)

    function checkEmail(event) {
        if (isEmail(event.target.value)) {
            setEmailValid(true);
            setEmailError("")
        } else {
            setEmailValid(false);
            setEmailError("Format d'email invalide")
        }
    }

    useEffect(() => {
        if (isConnected()) {
            setFamilyExist(true)
            setDisableUserExist(true)
        }
    }, [])

    function checkPhone(event) {
        if(isPhone(event.target.value)) {
            setPhoneValid(true);
        } else {
            setPhoneValid(false);
        }
    }

    function handleChangeEmail(event) {
        setFamily({...family, email: event.target.value});
    }

    return (
        <Grid2 sx={{p:2}} sm={12} xs={12}>
            <Typography variant={"h6"}>Famille et référent légal</Typography>
            <Divider />
            <Grid2 sx={{mt:1}} xs={12}>
                <Stack   direction="row"
                         justifyContent="center"
                         alignItems="center"
                         spacing={2}
                >
                    <FormGroup>
                        <FormControlLabel disabled={disableUserExist} control={<Switch  onChange={() => setFamilyExist(!familyExist)} checked={familyExist} />} label="Je possède déjà un compte" />
                    </FormGroup>
                </Stack>
            </Grid2>
            { familyExist && !disableUserExist ?
                <Grid2 xs={12} sm={12} md={12} xl={8} xlOffset={2} container spacing={2}>
                    <Grid2 xs={12}>
                        <FormControl fullWidth variant="outlined">
                            <TextField
                                required
                                value={family.email}
                                error={!emailValid}
                                onBlur={checkEmail}
                                onChange={(event) => setFamily({...family, email: event.target.value})}
                                id="email-input" label="Email" variant="outlined"
                            />
                        </FormControl>
                    </Grid2>
                    <Grid2 xs={12}>
                        <FormControl fullWidth variant="outlined">
                            <TextField
                                type="password"
                                required
                                value={family.password}
                                onChange={(event) => setFamily({...family, password: event.target.value})}
                                id="lastname-input" label="Mot de passe" variant="outlined" />
                        </FormControl>
                    </Grid2>
                    <Grid2 xs={12}>
                        <Stack direction={"row"} justifyContent={"space-between"} >
                            <Cm2cButton disabled={false} onClick={() => signIn(family.email, family.password)} >Se connecter</Cm2cButton>
                            <Typography style={{color: ""}}>
                                <i>
                                    <Link sx={{color: '#676767', textDecoration: "none"}}
                                          href={"/reset-password/ask"}>Mot de passe oublié</Link>
                                </i>
                            </Typography>
                        </Stack>
                    </Grid2>
                </Grid2>
                :
                <Grid2 xs={12} sm={12} md={12} xl={8} xlOffset={2} container spacing={2}>
                    <Grid2 xs={12}>
                        <FormControl fullWidth variant="outlined">
                            <TextField
                                required
                                disabled={isConnected()}
                                value={family.email}
                                error={!emailValid}
                                onBlur={checkEmail}
                                onChange={handleChangeEmail} id="email-input" label="Email" variant="outlined" />
                            <FormHelperText error={!emailValid} id="outlined-weight-helper-text">{emailError}</FormHelperText>
                        </FormControl>
                    </Grid2>
                    <Grid2 xs={12}>
                        <FormControl fullWidth variant="outlined">
                            <TextField
                                type="password"
                                required
                                disabled={isConnected()}
                                value={family.plainPassword}
                                onChange={(event) => setFamily({...family, plainPassword: event.target.value})}
                                id="lastname-input" label="Mot de passe" variant="outlined" />
                        </FormControl>
                    </Grid2>
                    <Grid2 xs={12}>
                        <Divider />
                    </Grid2>
                    <Grid2 xs={2}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="civility-select">Civilité</InputLabel>
                            <Select
                                required
                                value={family.civility}
                                onChange={(event) => setFamily({...family, civility: event.target.value})}
                                label="Civilité"
                            >
                                <MenuItem value={"Male"}>M.</MenuItem>
                                <MenuItem value={"Female"}>Mme</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid2>
                    <Grid2 xs={5}>
                        <FormControl fullWidth variant="outlined">
                            <TextField
                                required
                                value={family.lastname}
                                onChange={(event) => setFamily({...family, lastname: event.target.value})}
                                id="outlined-basic" label="Nom" variant="outlined" />
                            {/*<FormHelperText id="outlined-weight-helper-text">Weight</FormHelperText>*/}
                        </FormControl>
                    </Grid2>
                    <Grid2 xs={5}>
                        <FormControl fullWidth variant="outlined">
                            <TextField
                                required
                                value={family.firstname}
                                onChange={(event) => setFamily({...family, firstname: event.target.value})}
                                id="firstname-input" label="Prénom" variant="outlined" />
                        </FormControl>
                    </Grid2>
                    <Grid2 xs={6}>
                        <FormControl fullWidth variant="outlined">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    required={false}
                                    value={dayjs(family.birthDate)}
                                    onChange={(value) => setFamily({...family, birthDate: value})}
                                    label={"Date de naissance"} format={"DD/MM/YYYY"} />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid2>
                    <Grid2 xs={6}>
                        <FormControl fullWidth variant="outlined">
                            <TextField
                                required
                                value={family.phone}
                                onBlur={checkPhone}
                                error={!phoneValid}
                                onChange={(event) => setFamily({...family, phone: event.target.value})}
                                id="phone-input" label="Téléphone" variant="outlined" />
                            <FormHelperText id="outlined-weight-helper-text">Format : <b>0601020304</b></FormHelperText>
                        </FormControl>
                    </Grid2>
                    <Grid2 xs={12}>
                        <Divider />
                    </Grid2>
                    <Grid2 xs={8}>
                        <FormControl fullWidth variant="outlined">
                            <TextField
                                required
                                value={family.address}
                                onChange={(event) => setFamily({...family, address: event.target.value})}
                                id="address-input" label="Adresse" variant="outlined" />
                        </FormControl>
                    </Grid2>
                    <Grid2 xs={4}>
                        <FormControl fullWidth variant="outlined">
                            <TextField
                                required
                                value={family.postalCode}
                                onChange={(event) => setFamily({...family, postalCode: event.target.value})}
                                id="postalcode-input" label="Code postal" variant="outlined" />
                        </FormControl>
                    </Grid2>
                    <Grid2 xs={12}>
                        <FormControl fullWidth variant="outlined">
                            <TextField
                                required
                                value={family.city}
                                onChange={(event) => setFamily({...family, city: event.target.value})}
                                id="city-input" label="Ville" variant="outlined" />
                        </FormControl>
                    </Grid2>
                </Grid2>
            }
        </Grid2>
    );
}

export default Family