import React from 'react';
import {Offcanvas} from "react-bootstrap"

function ContextOffCanvas({displayDrawer, name, handleClose, children}) {
    return (
        <Offcanvas show={displayDrawer} onHide={() => handleClose()}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    <h4>{name}</h4>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                { children }
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default ContextOffCanvas