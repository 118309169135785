import React from 'react';
import {useContext, useEffect, useState} from "react";
import '../css/RowTemplate.css';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useParams, Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import { teal } from '@mui/material/colors';
import {FamilyContext} from "./context/FamilyContext";
import {PageContext} from "./context/PageContext";
import PageWrapper from "./PageWrapper";
import {Box, Paper, Stack, Step, StepLabel, Stepper, Tooltip, Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { styled } from '@mui/material/styles';
import Cm2cButton, {Cm2cLoadingButton} from "./Cm2cButton";
import {isEmail, isPhone} from "./Helper/Validator";
import Students from "./subscription/Students";
import Courses from "./subscription/Courses";
import Summary from "./subscription/Summary";
import Completed from "./subscription/Completed";
import Family from "./subscription/Family";
import {UserContext} from "./context/UserContext";
import {fetchApiPlatform, postApi, publicFetchApiPlatform, publicPostApi} from "./api/Content";
import {CourseContext} from "./context/CourseContext";
import {AlertContext} from "./context/AlertContext";
const steps = ['Famille', 'Éleve(s)', 'Cours', 'Récapitulatif'];

function Subscription() {
    const { setMessage, setOpen, setSeverity } = useContext(AlertContext)
    const { season, loadSeason } = useContext(AlertContext)
    const [activeStep, setActiveStep] = useState(0);
    const [stepValid, setStepValid] = useState(false);
    const [subscriptionEnbaled, setSubscriptionEnbaled] = useState(false);
    const [subscriptionSent, setSubscriptionSent] = useState(false);
    const [loading, setLoading] = useState(false);

    const { family, loadStudents, students, setFamily, loadFamily, sendSubscription, isSubscriptionEnabled} = useContext(FamilyContext)
    const { loadPage } = useContext(PageContext)
    const { getUser, isConnected, login } = useContext(UserContext)
    const { loadCourses } = useContext(CourseContext)

    useEffect(() => {
        publicFetchApiPlatform("/subscribe/enabled").then(data => {
            if (!data) {
                window.location.href = "/inscription-ferme";
            }
        }).catch(e => {
            setMessage(e.message)
            setSeverity("error")
            setOpen(true)
        });
        
        if (getUser() !== null) {
            loadFamily(getUser().id)
        }
    }, [])

    const QontoConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 10,
            left: 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: teal[500],
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: teal[500],
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderTopWidth: 3,
            borderRadius: 1,
        },
    }));

    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: teal[500],
        }),
        '& .QontoStepIcon-completedIcon': {
            color: teal[500],
            zIndex: 1,
            fontSize: 20,
        },
        '& .QontoStepIcon-circle': {
            width: 10,
            height: 10,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
    }));

    function QontoStepIcon(props) {
        const { active, completed, className } = props;

        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    <FontAwesomeIcon className="QontoStepIcon-completedIcon"  icon={faCheck} />
                ) : (
                    <div className="QontoStepIcon-circle" />
                )}
            </QontoStepIconRoot>
        );
    }

    const validateSubscription = () => {
        setLoading(true);
        postApi("/subscription/validate/" + getUser().id, "POST").then((data) => {
            setSubscriptionSent(true)
        }).catch(() => {
            console.log("ERREUR")
        })
    }

    const subscribreStudents = () => {
        setLoading(true);
        sendSubscription().then(data => {
            setLoading(false)
        })

        setActiveStep(activeStep+1)
    }

    const stepOneValid = () => {
        if (
            family.email === "" ||
            !isEmail(family.email) ||
            !isPhone(family.phone) ||
            family.civility === "" ||
            family.phone === "" ||
            family.firstname === "" ||
            family.lastname === "" ||
            //family.birthDate === "" ||
            family.address === "" ||
            family.postalCode === "" ||
            family.city === ""
        ) {
            return false;
        }

        return true
    }

    function stepTwoValid() {
        let valid = true;

        if (students.length === 0) {
            return false;
        }

        students.map((student) => {
            if (
                student.civility === "" ||
                student.firstname === "" ||
                student.lastname === "" ||
                student.birthDate === ""
            ) {
                valid = false;
            }
        })

        return valid
    }

    function subscribeUser() {
        if (getUser() === null && !isConnected()) {
            publicPostApi("/users", "POST", family)
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 400) {
                            response.json().then((data) => {
                                setMessage(response.status)
                            })
                            setSeverity("error")
                            setOpen(true)
                        }
                        if (response.status === 422) {
                            response.json().then((data) => {
                                setMessage(data["hydra:description"])
                            })
                            setSeverity("error")
                            setOpen(true)
                        }
                    } else {
                        response.json().then((data) => {
                            setFamily(data)
                            localStorage.setItem("user", JSON.stringify(data))
                            let pass =  data.lastname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase() + "!Cm2c!pass"

                            let body = {
                                "username": data.email,
                                "password": pass
                            }

                            publicPostApi("/login_check", "POST", body).then(response => {
                                if (response.ok) {
                                    response.json().then((data) =>
                                        login(data.token)
                                    )
                                }
                            })
                        })
                        setActiveStep(activeStep+1)
                    }
                })
        } else {
            let familyTmp = {...family};
            delete familyTmp.students
            delete familyTmp.roles

            postApi("/users/" + getUser().id, "PUT", familyTmp).then(data => {
                //setFamily(data)
            });
            setActiveStep(activeStep+1)
        }
    }

    // useEffect(() => {
    //     switch (activeStep) {
    //         case 0:
    //             validateStepOne();
    //             break;
    //         case 1:
    //             validateStepTwo();
    //             break;
    //     }
    // }, [family, activeStep])

    return (
        <PageWrapper>
            <Grid2 container >
                <Grid2  xs={12} sm={12} md={12} xl={8} xlOffset={2} sx={{m:1, p:1}} component={Paper} >
                    <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={QontoStepIcon}><b>{label}</b></StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Grid2>
                <Grid2 xs={12} sm={12} md={12} xl={8} xlOffset={2} sx={{m:1, p:2}} component={Paper} >
                    {activeStep == 0 && <Family />}
                    {activeStep == 1 && <Students />}
                    {activeStep == 2 && <Courses />}
                    {(activeStep == 3 && !subscriptionSent) && <Summary />}
                    {(activeStep == 3 && subscriptionSent)  && <Completed />}
                </Grid2>
                <Grid2 xs={12} sm={12} md={8} mdOffset={2} xl={8} xlOffset={2} sx={{m:1, p:1}}  component={Paper} >
                    { subscriptionSent ?
                        <Stack  direction="row" alignItems={"center"} justifyContent={"center"}>
                            <Link reloadDocument to={"/"}>
                                <Cm2cButton>
                                    Retour à l'accueil
                                </Cm2cButton>
                            </Link>
                        </Stack>
                        :
                        <Stack  direction="row" alignItems={"center"} justifyContent={"space-between"}>
                            <Cm2cButton disabled={activeStep === 0} onClick={() => setActiveStep(activeStep-1)} >Précédent</Cm2cButton>
                            <Box>
                                { ((activeStep === 0 && !stepOneValid()) || (activeStep === 1 && !stepTwoValid())) &&
                                    <Tooltip arrow title="Veuillez compléter tous les champs obligatoire avant de pouvoir passer à l'étape suivante" placement="left">
                                        <FontAwesomeIcon style={{color:"#fbc02d", verticalAlign: "middle", fontSize:"25px", marginRight:"10px"}}  icon={faTriangleExclamation} />
                                    </Tooltip>
                                }
                                { activeStep === 0 &&
                                    <Cm2cButton disabled={!stepOneValid()} onClick={() => subscribeUser()} >Valider Réferent Légal</Cm2cButton>
                                }
                                { activeStep === 1 &&
                                    <Cm2cButton disabled={!stepTwoValid()} onClick={() => setActiveStep(activeStep+1)} >Valider Les eleves</Cm2cButton>
                                }
                                { activeStep === 2 &&
                                    <Cm2cButton onClick={() => subscribreStudents()} >Valider Les cours</Cm2cButton>
                                }
                                { activeStep === 3 &&
                                    <Cm2cLoadingButton onClick={() => validateSubscription()}   loading={loading}>Valider mon inscription</Cm2cLoadingButton>
                                }
                            </Box>
                        </Stack>
                    }
                </Grid2>
            </Grid2>
        </PageWrapper>
    );
}

export default Subscription