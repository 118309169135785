import {React, useContext} from 'react';
import {Button, Col, Container, Row} from 'react-bootstrap';
import '../css/CardPage.css';

import {faCheck, faTimes, faEdit, faClone, faNewspaper, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PagePreview from "./PagePreview";
import {fetchApiPlatform} from "./api/Content";
import {AlertContext} from "./context/AlertContext";
import Grid2 from "@mui/material/Unstable_Grid2";

function CardPage({page = undefined}) {
    const { setMessage, setDisplayToast, setVariant, setTitle } = useContext(AlertContext)

    const duplicatePage = () => {
        fetchApiPlatform("/pages/duplicate/" + page.slug).then(data => {
            window.location.reload()
        })
    }

    const removePage  = () => {
        fetchApiPlatform("/pages/" + page.slug, "DELETE").then(data => {
            window.location.reload()
        })
    }

    const deleteItem = <Row>
        <Col className={"col-12"}>
            <p>Etes Vous sur de vouloir supprimer la page <b>{page.title}</b> ?</p>
        </Col>
        <Col className={"col-12"}>
            <Button size={"sm"} onClick={() => {removePage()}} variant={"danger"} className={"btn-card-page"}><FontAwesomeIcon icon={faTimes}
            /> Supprimer</Button>&nbsp;&nbsp;&nbsp;
            <Button size={"sm"} onClick={() => {setDisplayToast(false)}} variant={"light"} className={"btn-card-page"}><FontAwesomeIcon icon={faCheck}
            /> Annuler</Button>
        </Col>
    </Row>

    const handleRemovePage = () => {
        setMessage(deleteItem)
        setVariant("warning")
        setTitle("Supprimer une page ?")
        setDisplayToast(true)
    }

    return (
        <Grid2 lg={3} md={3} sm={6} xs={12}>
            <Grid2 className={"card-page paper-shadow"} style={{height: "320px"}}>
                <Row className={"menu-hover justify-content-center align-items-center"} style={{width: "100%"}}>
                    <Col className={"col-12"}>
                        <h3><b><i>{page.title}</i></b></h3>
                    </Col>
                    <Col className={"col-12 text-center mt-3"}>
                        <Button href={"/pages/" + page.slug} style={{width: "100%"}}  variant={"outline-light"} className={"btn-card-page"}><FontAwesomeIcon icon={faEdit}
                        />  Editer</Button>
                    </Col>
                    <Col className={"col-12 text-center mt-3"}>
                        <Button target={"_blank"} href={"/" + page.slug} style={{width: "100%"}} variant={"outline-light"} className={"btn-card-page"}><FontAwesomeIcon icon={faNewspaper}
                        /> Visiter la page</Button>
                    </Col>
                    <Col className={"col-12 text-center mt-3"}>
                        <Button onClick={() => duplicatePage()} style={{width: "100%"}} variant={"outline-light"} className={"btn-card-page"}><FontAwesomeIcon icon={faClone}
                        /> Dupliquer la page</Button>
                    </Col>
                    <Col className={"col-12 text-center mt-3"}>
                        <Button onClick={() => handleRemovePage()} style={{width: "100%"}} variant={"outline-danger"} className={"btn-card-page"}><FontAwesomeIcon icon={faTrash}
                        /> Supprimer</Button>
                    </Col>
                </Row>
                <div id="wrap">
                    <iframe id="frame"
                            title="Inline Frame Example"
                            src={"/" + page.slug}>
                    </iframe>
                </div>
                {/*<PagePreview page={page} />*/}
            </Grid2>
        </Grid2>
    );
}

export default CardPage