import React from 'react';
import {useContext} from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
    AlertTitle, Chip, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography
} from "@mui/material";
import {FamilyContext} from "../context/FamilyContext";
import {
    faEnvelope, faGraduationCap,
    faPhone,
    faUserTie
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {teal} from "@mui/material/colors";
import {getCivility, getCourseType} from "../Helper/Civility";
import {Cm2cAlert} from "../Cm2cButton";
import {formatter} from "../Helper/Validator";
import dayjs from "dayjs";
import {SeasonContext} from "../context/SeasonContext";

function Summary() {
    const { family, students, getSubTotalStudent } = useContext(FamilyContext)
    const { season } = useContext(SeasonContext)

    const getTotalInvoice = () => {
        let total = 0
        students.map((student) => {
            total = total + getSubTotalStudent(student)
        })

        return total + 2200
    }

    return (
        <Grid2>
            <Grid2 container spacing={2}>
            <Grid2 sm={12} >
                <Typography variant={"h5"}>Référent légal</Typography>
                <Divider sx={{mb:3}} />
            </Grid2>
            <Grid2 sm={12} xs={12} xl={6} md={6} sx={{p:1}}>
                <Paper sx={{p:1}} elevation={4}>
                    <Grid2 xs={12} >
                        <Typography style={{color: teal[700], fontSize: "19px"}}>
                            <FontAwesomeIcon style={{color: teal[500], padding: "5px",verticalAlign: "middle", fontSize:"25px"}}  icon={faUserTie} />
                            <b><i>{getCivility(family.civility)} {family.firstname} {family.lastname}</i></b>
                        </Typography>
                        <Divider />
                    </Grid2>
                    <Grid2 xs={12}>
                        <Typography>
                            <FontAwesomeIcon style={{padding: "5px",verticalAlign: "middle", fontSize:"20px"}}  icon={faEnvelope} />
                            {family.email}
                        </Typography>
                        <Typography>
                            <FontAwesomeIcon style={{padding: "5px",verticalAlign: "middle", fontSize:"20px"}}  icon={faPhone} />
                            {family.phone}
                        </Typography>
                        <Typography sx={{mt:1}}>
                            {family.address} {family.postalCode}<br />
                            {family.city}
                        </Typography>
                    </Grid2>
                </Paper>
            </Grid2>
            <Grid2 sm={12} xs={12} xl={6} md={6} sx={{p:1}} >
                <Cm2cAlert>
                    <AlertTitle><b>Informations concernant votre facturation</b></AlertTitle>
                    Le prix total indiqué ne reflète pas forcément ce que vous allez payer, il est donné à titre indicatif mais pourra changer
                    en fonction de la tenue de certains ateliers par exemple.<br/>
                    <u>Nous ajoutons également 22,00 € de cotisations.</u><br/>
                    <br/>
                    <b>Montant total : {formatter.format(getTotalInvoice() /100)}</b>
                </Cm2cAlert>
            </Grid2>
            </Grid2>
            <Grid2 sx={{mb:3, mt:3}} sm={12} >
                <Typography variant={"h6"}>Éleve(s) et cours</Typography>
                <Divider sx={{mb:3}} />
            </Grid2>
            <Grid2 container spacing={2}>
                { students.filter((student) => !student.disableForSeason).map((student, i) =>
                    <Grid2 key={i} sm={12} xs={12} xl={6} md={6} >
                        <Paper sx={{p:1}}  elevation={4}>
                            <Typography style={{color: teal[700], fontSize: "19px"}}>
                                <FontAwesomeIcon style={{color: teal[500], padding: "5px",verticalAlign: "middle", fontSize:"25px"}}  icon={faGraduationCap} />
                                <b><i>{getCivility(student.civility)} {student.firstname} {student.lastname}</i></b>
                            </Typography>
                            <Divider />
                            <Typography sx={{p:1}}>
                                {dayjs(student.birthDate).format('DD/MM/YYYY')}
                                { student.cycleValidated &&
                                    <>&nbsp;&nbsp;<Chip size={"small"} color={"success"} label="Cycle 1 validé"></Chip></>
                                }
                            </Typography>
                            <TableContainer component={Paper}>
                            <Table size="small">
                                <TableBody>
                                    {student.privateCourses.map((course, i) =>
                                        (course.season.id === season.id) &&
                                        <TableRow
                                            key={i}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell>{getCourseType(course.type)}</TableCell>
                                            <TableCell component="th" scope="row">{course.title}</TableCell>
                                            <TableCell>{course.duration}</TableCell>
                                            <TableCell>{formatter.format(course.price / 100)}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    )}
                                    { student.workshops.map((course, i) =>
                                        (course.season.id === season.id) &&
                                            <TableRow
                                                key={i}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell>{getCourseType(course.type)}</TableCell>
                                                <TableCell component="th" scope="row">{course.title}</TableCell>
                                                <TableCell>{course.duration}</TableCell>
                                                <TableCell>{formatter.format(course.price / 100)}</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        )}
                                </TableBody>
                            </Table>
                            <Grid2 sx={{p:2}} mdOffset={4} md={8} smOffset={2} sm={10}  xs={12}>
                                <Cm2cAlert>
                                    Sous total : <b>{formatter.format(getSubTotalStudent(student) / 100)}</b>
                                </Cm2cAlert>
                            </Grid2>
                            </TableContainer>
                        </Paper>
                    </Grid2>
                )}
            </Grid2>
        </Grid2>
    );
}

export default Summary