import {useState, createContext, useContext} from "react";
import {fetchApiPlatform, publicPostApi, publicFetchApiPlatform} from "../api/Content";
import {AlertContext} from "./AlertContext";
import dayjs from "dayjs";
import {SeasonContext} from "./SeasonContext";

export const FamilyContext = createContext()

export const FamilyProvider = ({ children }) => {
    const { setMessage, setOpen, setSeverity } = useContext(AlertContext)
    const { season, loadSeason } = useContext(SeasonContext)
    
    const [students, setStudents] = useState([]);
    const [family, setFamily] = useState({
        email: "",
        civility: "",
        firstname: "",
        lastname: "",
        birthDate: "",
        address: "",
        phone: "",
        postalCode: "",
        isStudent: false,
        city: "",
    });

    const loadStudents = (familyId) => {
        fetchApiPlatform("/students?family=" + familyId).then(data => {
            setStudents(data["hydra:member"])
        })
    }

    const isSubscriptionEnabled = () => {
        publicFetchApiPlatform("/subscribe/enabled").then(data => {
            if (data) {
                window.location.href = "/inscription";
            }
        }).catch(e => {
            console.log(e)
        })
    }

    const loadFamily = (familyId) => {
        fetchApiPlatform("/users/" + familyId).then(data => {
            setFamily(data)
            loadStudents(data.id)
        })
    }

    const updateStudent = (studentToUpdate) => {
        let currentStudents = [...students];
        let index = currentStudents.findIndex(student => studentToUpdate.id === student.id)

        if (index === -1) {
            return
        }

        currentStudents.splice(index, 1, studentToUpdate)
        setStudents(currentStudents)
    }

    const deleteStudent = (studentToDelete) => {
        let currentStudents = [...students]

        let index = students.findIndex(student => studentToDelete.id === student.id)

        if (index === -1) {
            return
        }

        currentStudents.splice(index, 1)

        setStudents(currentStudents)
    }

    const sendSubscription = async () => {
        students.map((student, i) => {
            let coursesToPost = [];
            student.privateCourses.map((course, i) => {
                if (course.season.id === season.id) {
                    coursesToPost.push(course["@id"])
                }
            })

            student.workshops.map((course, i) => {
                if (course.season.id === season.id) {
                    coursesToPost.push(course["@id"])
                }
            })

            let currentStudent = {...student}
            delete currentStudent.id
            if (typeof student["@id"] === "undefined") {
                publicPostApi("/subscribe/student", "POST", currentStudent).then(response => {
                    if (!response.ok) {
                        setSeverity("error")
                        response.json().then(data => {
                            setMessage(data["hydra:description"])
                            setOpen(true)
                        })
                    } else {
                        response.json().then(data => {
                            publicPostApi("/subscribe/student-course/" + data.id, "PUT", coursesToPost).then(response => {
                                if (!response.ok) {
                                    setSeverity("error")
                                    response.json().then(data => {
                                        setMessage(data["hydra:description"])
                                        setOpen(true)
                                    })
                                }
                            })
                        })
                    }
                })
            } else {
                delete currentStudent.courses
                delete currentStudent.workshops
                publicPostApi("/subscribe/student/" + student.id, "PUT", currentStudent).then(response => {
                    updateStudent(student)
                    response.json().then(data => {
                        publicPostApi("/subscribe/student-course/" + data.id, "PUT", coursesToPost).then(response => {
                            if (!response.ok) {
                                setSeverity("error")
                                response.json().then(data => {
                                    setMessage(data["hydra:description"])
                                    setOpen(true)
                                })
                            }
                        })
                    })
                })
            }
        })
    }

    const getSubTotalStudent = (student) => {
        let subTotal = 0

        if (student.disableForSeason) {
            return subTotal;
        }

        student.privateCourses.map((course) => {
            if (course.season.id === season.id) {
                subTotal = subTotal + course.price
            }
        })

        student.workshops.map((course) => {
            if (course.season.id === season.id) {
                subTotal = subTotal + course.price
            }
        })

        return subTotal
    }


    return (
        <FamilyContext.Provider value={{isSubscriptionEnabled, getSubTotalStudent, season, loadStudents, family, setFamily, updateStudent, deleteStudent, sendSubscription, loadFamily, students, setStudents}}>
            {children}
        </FamilyContext.Provider>
    )
}