import {useState, createContext} from "react";

export const RawContext = createContext()

export const RawProvider = ({ children }) => {
    const [displayRaw, setDisplayRaw] = useState(false);

    return (
        <RawContext.Provider value={{ displayRaw, setDisplayRaw }}>
            {children}
        </RawContext.Provider>
    )
}