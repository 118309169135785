import {useContext, useEffect} from 'react';
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faPager} from "@fortawesome/free-solid-svg-icons";
import {UserContext} from "./context/UserContext";
import Page from "./menu/Page";

function MenuBar({show = false}) {
    const { getUser, logout } = useContext(UserContext)

    return (
        <Navbar bg="dark" variant="dark" className={"p-0"} expand={"lg"}>
            <Container >
                <Navbar.Brand href="#">
                    <img
                        src="https://medias-jb.s3.amazonaws.com/cm2c/pone-641e3d5e60500.png"
                        width="80"
                        height="80"
                        className="d-inline-block align-top p-0"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/pages/new"><FontAwesomeIcon icon={faAdd} />  Créer une page</Nav.Link>
                    </Nav>
                    <Nav>
                        <NavDropdown title={getUser().fullname} id="basic-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Administration</NavDropdown.Item>
                            {/*<NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={() => logout()}>
                                Se déconnecter
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default MenuBar