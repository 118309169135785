import {useState, createContext, useContext} from "react";
import {fetchApiPlatform, publicFetchApiPlatform} from "../api/Content";
import {AlertContext} from "./AlertContext";
import {FamilyContext} from "./FamilyContext";
import {SeasonContext} from "./SeasonContext";

export const CourseContext = createContext()

export const CourseProvider = ({ children }) => {
    const { setMessage, setDisplayToast, setVariant, setTitle } = useContext(AlertContext)
    const { season } = useContext(SeasonContext)

    const [courses, setCourses] = useState([]);
    const [workshops, setWorkshops] = useState([]);

    const loadCourses = () => {
        publicFetchApiPlatform("/courses?season=" + season.id).then(data => {
            let courseArray = [];
            let workshopArray = [];

            data["hydra:member"].map((course) => {
                if (course.type === "private-lesson") {
                    courseArray.push(course);
                } else {
                    workshopArray.push(course);
                }
            })
            setCourses(courseArray);
            setWorkshops(workshopArray);
        })
    }

    return (
        <CourseContext.Provider value={{ loadCourses, courses, workshops }}>
            {children}
        </CourseContext.Provider>
    )
}