import {useState, createContext, useEffect, useContext} from "react";
import {fetchApiPlatform, publicPostApi} from "../api/Content";
import {AlertContext} from "./AlertContext";
import {FamilyContext} from "./FamilyContext";

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState({civility: "", id: undefined, students: []});

    const login = (token) => {
        localStorage.setItem("token", token)

        fetchApiPlatform("/me").then(data => {
            localStorage.setItem("user", JSON.stringify(data))
        })
    }

    const signIn = async (username, password, redirectTo = undefined) => {
        let body = {
            "username": username,
            "password": password
        }

        publicPostApi("/login_check", "POST", body).then(response => {
            if (response.ok) {
                response.json().then((data) => {
                    localStorage.setItem("token", data.token)
                    fetchApiPlatform("/me").then(data => {
                        localStorage.setItem("user", JSON.stringify(data))
                        if (typeof redirectTo === "undefined") {
                            window.location.reload();
                        } else {
                            window.location.href = redirectTo;
                        }
                    })
                })
            }
        })
    }

    const logout = () => {
        localStorage.removeItem("token")
        localStorage.removeItem("user")
        window.location.reload();
    }

    const getUser = () => {
        return JSON.parse(localStorage.getItem("user"));
    }

    const loadUser = () => {
        let vari = getUser().id
        fetchApiPlatform("/users/" + vari).then(data => {
            setUser(data)
        })
    }

    const isConnected = () => {
        if (localStorage.getItem("token") !== null) {
            return true
        }

        return false;
    }

    const check = () => {
        fetchApiPlatform("/me").then(data => {
            localStorage.setItem("user", JSON.stringify(data))
            window.location.href = "/pages"
        })
    }

    return (
        <UserContext.Provider value={{signIn, loadUser, getUser, login, isConnected, logout }}>
            {children}
        </UserContext.Provider>
    )
}