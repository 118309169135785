import {React, useState, useEffect, useContext} from "react";
import {Col, Row, Container, Button, Accordion} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faImage, faFont} from "@fortawesome/free-solid-svg-icons";

import { SketchPicker } from 'react-color';
import RangeRow from "./fields/RangeRow";
import ImageMenu from "./ImageMenu";
import {PageContext} from "../context/PageContext";

function Content({col = {}, lineId = 0}) {
    const [colId] = useState(col.id);
    const [contentType, setContentType] = useState(col.content.type);
    const [padding, setPadding] = useState(col.content.padding);
    const [rounded, setRounded] = useState(col.content.rounded);
    const [backgroundColor, setBackgroundColor] = useState(col.content.backgroundColor);

    const TYPES_AS_LABELS = [
        {
            "type" : "text",
            "label": "Texte",
            "content": undefined
        },
        {
            "type" : "image",
            "label": "Image",
            "content": <ImageMenu lineId={lineId} col={col} />
        }
    ]

    const getLabel = (searchType) => TYPES_AS_LABELS.find(typeElement => typeElement.type === searchType).label;
    const getContent = (searchType) => TYPES_AS_LABELS.find(type => type.type === searchType).content;

    const { updateContent } = useContext(PageContext)

    useEffect(() => {
        updateContent(lineId, colId, contentType, backgroundColor, padding, rounded)
    }, [contentType, backgroundColor, padding, rounded])

    return (
        <Container>
            <Row>
                <div className={"d-flex"}>
                    <Button onClick={() => setContentType("text")} className={"button-col"}><FontAwesomeIcon icon={faFont} /> Texte</Button>
                    {/*<Button onClick={() => setContentType("table")} className={"ms-1 button-col"}><FontAwesomeIcon icon={faTable} /> Tableau</Button>*/}
                    <Button onClick={() => setContentType("image")} className={"ms-1 button-col"}><FontAwesomeIcon icon={faImage} /> Image</Button>
                </div>
            </Row>
            <Row className={"mt-3 mb-3"} style={{borderBottom: "1px solid black"}} ></Row>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Options du contenu</Accordion.Header>
                    <Accordion.Body>
                        <Row className={""} >
                            <Col className={"col-12"}><h6>Couleur de fond</h6></Col>
                            <SketchPicker color={backgroundColor}
                                          onChangeComplete={(color, event) => setBackgroundColor(color.hex)}
                                          onChange={(color, event) => setBackgroundColor(color.hex)}
                            />
                            { process.env.REACT_APP_BACK_HOST }
                            <RangeRow min={0} max={5} label={"Marge"} value={padding} onChange={e => setPadding(e.target.value)} />
                            <RangeRow min={0} max={5} label={"Arrondi"} value={rounded} onChange={e => setRounded(e.target.value)} />
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>{getLabel(contentType)}</Accordion.Header>
                    <Accordion.Body>
                        {getContent(contentType)}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>


        </Container>
    );
}

export default Content