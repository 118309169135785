import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Container} from "react-bootstrap";
import '../css/RowTemplate.css';
import Text from "./content/Text";
import ImageContent from "./content/ImageContent";

function PagePreview({page}) {
    return (
        <Container fluid={page.containerWidth} className={"p-2"}>
            <Container id={"content"} fluid>
                {
                    page.template.map((line, i) => (
                        <Row key={i} className={"position-relative"}>
                            {line.cols.map((col, j) =>
                                <Col
                                    key={j}
                                    className={"preview rounded-" + col.content.rounded +" p-" + col.content.padding +  " position-relative "} lg={col.col}
                                    style={{backgroundColor: col.content.backgroundColor}}
                                >
                                    {(col.content.type === "text") && (<Text preview lineId={line.id} col={col} />)}
                                    {(col.content.type === "image") && (<ImageContent lineId={line.id} col={col} />)}
                                </Col>
                            )}
                        </Row>
                    ))
                }
            </Container>
        </Container>
    );
}

export default PagePreview