import React from 'react';
import {useContext, useEffect} from "react";
import {Col, Row} from 'react-bootstrap';
import {Container} from "react-bootstrap";
import '../css/RowTemplate.css';

import { useParams } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faGripLines} from "@fortawesome/free-solid-svg-icons";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

import Line from "./menu/Line";
import {ContentContext} from "./context/ContentContext";
import LineContent from "./LineContent";
import {RawContext} from "./context/RawContext";
import {PageContext} from "./context/PageContext";
import TemplatorMenuBar from "./TemplatorMenuBar";
import TemplatorMenuBarV2 from "./TemplatorMenuBarV2";

function Templator({isPreview = false}) {
    const { setDisplayDrawer, setContextDrawer, setContextName } = useContext(ContentContext)
    const { setDisplayRaw } = useContext(RawContext)
    const { preview, setPreview, page, updatePage, loadPage } = useContext(PageContext)
    let { slug } = useParams();

    useEffect(() => {
        if (typeof slug !== "undefined") {
            loadPage(slug)
        }

        setPreview(isPreview)

        if (isPreview) {
            setDisplayRaw(true)
        }
    }, [])

    const lineMenu = () => {
        setDisplayDrawer(true)
        setContextName(<><FontAwesomeIcon icon={faGripLines} />  Ligne</>)
        setContextDrawer(<Line />)
    }

    return (
        <>
        { !preview &&
            // <Container fluid className={"g-0 p-2 menu"}>
            //     <Row>
            //         <Col lg={12} className={"d-flex menu-flex"}>
            //
            //             <ButtonGroup  size="sm">
            //                 <Button variant={"secondary"} className={(!displayRaw) ? "active" : ""} onClick={() => handleDisplayRaw(false)}><FontAwesomeIcon icon={faThLarge} /> Vue Block</Button>
            //                 <Button variant={"secondary"} className={(displayRaw) ? "active" : ""} onClick={() => handleDisplayRaw(true)}><FontAwesomeIcon icon={faNewspaper} />Vue Finale</Button>
            //             </ButtonGroup>
            //             <ButtonGroup  size="sm">
            //                 <Button variant={"info"} className={(page.containerWidth === "fluid") ? "active" : ""} onClick={() => handleChangePageSize("fluid")}><FontAwesomeIcon icon={faArrowsH} /> Largeur Max.</Button>
            //                 <Button variant={"info"} className={(page.containerWidth === "xl") ? "active" : ""} onClick={() => handleChangePageSize("xl")}><FontAwesomeIcon icon={faGripLinesVertical} /> Largeur XL</Button>
            //             </ButtonGroup>
            //             { (typeof pageId !== "undefined") ?
            //                 <Button size={"sm"} onClick={editTemplate} variant="success"><FontAwesomeIcon icon={faSave} /> Sauvegarder Modifications</Button>
            //                 :
            //                 <Button size={"sm"} onClick={() => saveTemplate()} variant="success"><FontAwesomeIcon icon={faSave} /> Sauvegarder</Button>
            //             }
            //
            //         </Col>
            //     </Row>
            // </Container>
            <TemplatorMenuBarV2 />
        }

        <Container fluid={page.containerWidth} className={"p-2 paper-shadow"} >
            <Container id={"content"} fluid>
                {
                    page.template.map((line, i) => (
                        <LineContent line={line} key={line.id} />
                    ))
                }
            </Container>
            { !preview &&
                <Row className={"add-line-row"}>
                    <Col className="col-12 mt-3">
                        <p onClick={lineMenu} className={"m-1 clickable text-center"}>
                            <FontAwesomeIcon size={"2xl"} icon={faCirclePlus} />&nbsp; Ligne
                        </p>
                    </Col>
                </Row>
            }
        </Container>
        </>
    );
}

export default Templator