import React from 'react';
import {useContext} from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
    AlertTitle, Chip, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography
} from "@mui/material";
import {FamilyContext} from "../context/FamilyContext";
import {
    faEnvelope, faGraduationCap,
    faPhone,
    faUserTie
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {teal} from "@mui/material/colors";
import {getCivility, getCourseType} from "../Helper/Civility";
import {Cm2cAlert} from "../Cm2cButton";
import {formatter} from "../Helper/Validator";
import dayjs from "dayjs";
import MusicNoteOutlinedIcon from '@mui/icons-material/MusicNoteOutlined';

function Completed() {
    const { family, getSubTotalStudent } = useContext(FamilyContext)

    const getTotalInvoice = () => {
        let total = 0
        family.students.map((student) => {
            total = total + getSubTotalStudent(student)
        })

        return total + 2200
    }

    return (
        <Grid2>
            <Grid2 container spacing={2}>
                <Grid2 sm={12} >
                    <Typography variant={"h5"}>Merci, votre inscription a bien été prise en compte ! <MusicNoteOutlinedIcon fontSize={"large"} /></Typography>
                    <Divider sx={{mb:3}} />
                </Grid2>
                <Grid2 sm={12} >
                    <Typography variant={"p"}>Le Centre musical et toute son équipe vous remercie pour votre inscription.</Typography>
                </Grid2>
                <Grid2 sm={12} xs={12} xl={12} md={12} sx={{p:1}} >
                    <Cm2cAlert>
                        <AlertTitle><b>La suite de votre inscription</b></AlertTitle>
                        { family.enabled ?
                            <>
                                Vous n'avez plus à rien faire ! Nous vous tiendrons informés de la prise en charge de votre dossier.
                            </>
                            :
                        <>
                            Afin de pouvoir consulter votre dossier d'inscription, un compte vous a été créer.<br/>
                            Un mail de confirmation avec votre identifiant et votre mot de passe vous a été envoyé par mail.<br/>
                        </>
                        }

                    </Cm2cAlert>
                </Grid2>
            </Grid2>
        </Grid2>
    );
}

export default Completed