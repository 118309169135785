import React from 'react';
import {useState ,useContext, useEffect} from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
    FormControl,
    InputLabel, MenuItem, Select,
    TextField,
} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {FamilyContext} from "../context/FamilyContext";
import {isMinor} from "../Helper/Validator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeftRotate, faDeleteLeft, faTrash} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

function StudentForm({number = undefined, student = undefined}) {
    const [currentStudent, setCurrentStudent] = useState(student);
    const [isChild, setIsChild] = useState(student);
    const { family, setFamily, updateStudent, deleteStudent} = useContext(FamilyContext)

    useEffect(() => {
        updateStudent(currentStudent)
    }, [currentStudent])

    useEffect(() => {
        setIsChild(isMinor(currentStudent.birthDate))
    }, [currentStudent.birthDate])

    const patchStudent = () => {
        currentStudent.courses = [];
        currentStudent.privateCourses = [];
        currentStudent.workshops = [];
        currentStudent.disableForSeason = true
        updateStudent(currentStudent)
    }

    const reactiveStudent = () => {
        currentStudent.disableForSeason = false
        updateStudent(currentStudent)
    }

    return (
        <Grid2 sx={{mt:1}} xs={12} sm={12} md={12} xl={12} container spacing={2}>
            <Grid2 xs={2}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="civility-select">Civilité</InputLabel>
                    <Select
                        disabled={currentStudent.isReferent || currentStudent.disableForSeason}
                        required
                        value={currentStudent.civility}
                        onChange={(event) => setCurrentStudent({...currentStudent, civility: event.target.value})}
                        label="Civilité"
                    >
                        <MenuItem value={"Male"}>M.</MenuItem>
                        <MenuItem value={"Female"}>Mme</MenuItem>
                    </Select>
                </FormControl>
            </Grid2>
            <Grid2 xs={3}>
                <FormControl fullWidth variant="outlined">
                    <TextField
                        disabled={currentStudent.isReferent || currentStudent.disableForSeason}
                        required
                        value={currentStudent.lastname}
                        onChange={(event) => setCurrentStudent({...currentStudent, lastname: event.target.value})}
                        id="outlined-basic" label="Nom" variant="outlined" />
                    {/*<FormHelperText id="outlined-weight-helper-text">Weight</FormHelperText>*/}
                </FormControl>
            </Grid2>
            <Grid2 xs={3}>
                <FormControl fullWidth variant="outlined">
                    <TextField
                        disabled={currentStudent.isReferent || currentStudent.disableForSeason}
                        required
                        value={currentStudent.firstname}
                        onChange={(event) => setCurrentStudent({...currentStudent, firstname: event.target.value})}
                        id="firstname-input" label="Prénom" variant="outlined" />
                </FormControl>
            </Grid2>
            <Grid2 xs={3}>
                <FormControl fullWidth variant="outlined">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            disabled={currentStudent.isReferent || currentStudent.disableForSeason}
                            value={dayjs(currentStudent.birthDate)}
                            onChange={(value) => setCurrentStudent({...currentStudent, birthDate: value})}
                            label={"Date de naissance"} format={"DD/MM/YYYY"} />
                    </LocalizationProvider>
                </FormControl>
            </Grid2>
            <Grid2 xs={1}>
                { !currentStudent.isReferent && (
                    <>
                    {(typeof currentStudent["@id"] === "undefined") ?
                        <FontAwesomeIcon onClick={() => deleteStudent(currentStudent)} style={{color:"#cc2828", fontSize:"25px", marginLeft: "10px", marginTop:"13px"}}  icon={faTrash} />
                        :
                        (!currentStudent.disableForSeason) ?
                            <FontAwesomeIcon onClick={() => patchStudent(currentStudent)} style={{color:"#ff832f", fontSize:"25px", marginLeft: "10px", marginTop:"13px"}}  icon={faDeleteLeft} />
                            :
                            <FontAwesomeIcon onClick={() => reactiveStudent(currentStudent)} style={{color:"#8f8f8f", fontSize:"25px", marginLeft: "10px", marginTop:"13px"}}  icon={faArrowLeftRotate} />
                    }
                    </>
                    )
                }
             </Grid2>
            { !isChild &&
            <>
                <Grid2 xs={6}>
                    <FormControl fullWidth variant="outlined">
                        <TextField
                            disabled={currentStudent.isReferent || currentStudent.disableForSeason}
                            value={currentStudent.email}
                            onChange={(event) => setCurrentStudent({...currentStudent, email: event.target.value})}
                            id="outlined-basic" label="Email" variant="outlined" />
                        {/*<FormHelperText id="outlined-weight-helper-text">Weight</FormHelperText>*/}
                    </FormControl>
                </Grid2>
                <Grid2 xs={6}>
                    <FormControl fullWidth variant="outlined">
                        <TextField
                            disabled={currentStudent.isReferent || currentStudent.disableForSeason}
                            value={currentStudent.phone}
                            onChange={(event) => setCurrentStudent({...currentStudent, phone: event.target.value})}
                            id="outlined-basic" label="Téléphone" variant="outlined" />
                        {/*<FormHelperText id="outlined-weight-helper-text">Weight</FormHelperText>*/}
                    </FormControl>
                </Grid2>
            </>
            }

        </Grid2>
    );
}

export default StudentForm