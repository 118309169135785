import React from 'react';
import {useContext, useEffect} from "react";
import '../css/Pager.css';

import {Container, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import LineContent from "./LineContent";
import {PageContext} from "./context/PageContext";
import {RawContext} from "./context/RawContext";
import PageWrapper from "./PageWrapper";

function NotFound() {
    const { page, loadPage, setPreview } = useContext(PageContext)
    const { setDisplayRaw } = useContext(RawContext)
    let { slug } = useParams();

    useEffect(() => {
    }, [])

    return (
        <PageWrapper>
            <Container fluid={page.containerWidth} >
                <Container id="content" fluid>
                    <h1>No No No</h1>
                </Container>
            </Container>
        </PageWrapper>
    );
}

export default NotFound