import React from 'react';
import {useContext, useEffect, useState} from "react";
import '../css/RowTemplate.css';
import { useSearchParams, Link } from 'react-router-dom';
import {FamilyContext} from "./context/FamilyContext";
import PageWrapper from "./PageWrapper";
import {FormControl, Paper, TextField, Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Cm2cButton from "./Cm2cButton";
import {UserContext} from "./context/UserContext";
import {publicPostApi} from "./api/Content";
import {AlertContext} from "./context/AlertContext";
const steps = ['Famille', 'Éleve(s)', 'Cours', 'Récapitulatif'];

function ResetPassword() {
    const [password, setPassword] = useState("");
    const [repeatedPassword, setRepeatedPassword] = useState("");

    const [params, setParams] = useSearchParams();

    useEffect(() => {
        if (params.get("token") === null) {
            window.location.href = "/"
        }
    }, [])

    function sendRequest() {
        publicPostApi("/reset-password", "POST", {
            "password": password,
            "repeatedPassword": repeatedPassword,
            "token": params.get("token"),
        }).then(response => {

        });
    }

    return (
        <PageWrapper>
            <Grid2 container spacing={2} >
                <Grid2 md={6} xlOffset={4} xl={4} >
                    <Paper elevation={2}>
                        <Grid2 xs={12}>
                            <Typography variant={"h6"}>Nouveau mot de passe</Typography>
                        </Grid2>
                        <Grid2 xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <TextField
                                    type="password"
                                    required
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                    id="lastname-input" label="Nouveau mot de passe" variant="outlined" />
                            </FormControl>
                        </Grid2>
                        <Grid2 xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <TextField
                                    type="password"
                                    required
                                    value={repeatedPassword}
                                    onChange={(event) => setRepeatedPassword(event.target.value)}
                                    id="lastname-input" label="Répétez le mote de passe" variant="outlined" />
                            </FormControl>
                        </Grid2>
                        <Grid2 xs={12}>
                            <Cm2cButton disabled={false} onClick={() => sendRequest()} >Valider</Cm2cButton>
                        </Grid2>
                    </Paper>
                </Grid2>
            </Grid2>
        </PageWrapper>
    );
}

export default ResetPassword