import {React} from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { teal } from '@mui/material/colors';
import LoadingButton from "@mui/lab/LoadingButton";


function Cm2cButton({children, onClick = () => {}, disabled = false}) {
    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText(teal[500]),
        backgroundColor: teal[500],
        '&:hover': {
            backgroundColor: teal[700],
        },
    }));

    return (
        <ColorButton  color="success" disabled={disabled} onClick={() => onClick()} variant="contained">{children}</ColorButton>
    );
}

export function Cm2cLoadingButton({children, onClick = () => {}, loading= false, disabled = false}) {
    const ColorLoadingButton = styled(LoadingButton)(({ theme }) => ({
        color: theme.palette.getContrastText(teal[500]),
        backgroundColor: teal[500],
        '&:hover': {
            backgroundColor: teal[700],
        },
    }));

    return (
        <ColorLoadingButton color="success" loading={loading} disabled={disabled} onClick={() => onClick()} variant="contained">{children}</ColorLoadingButton>
    );
}

export function Cm2cAlert({children, onClick = () => {}, disabled = false}) {
    const ColorAlert = styled(Alert)(({ theme }) => ({
        color: theme.palette.getContrastText(teal[500]),
        severity: "info",
        backgroundColor: teal[100],
        '&': {
            color: teal[900],
        },
        '.MuiAlert-icon': {
            color: teal[900],
        },
    }));

    return (
        <ColorAlert severity="info" >{children}</ColorAlert>
    );
}

export default Cm2cButton