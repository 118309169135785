import React from 'react';
import {useState, useEffect ,useContext} from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { AlertTitle, Autocomplete, Checkbox, Chip,
    Divider, FormControl, FormControlLabel, FormGroup, TextField, Typography
} from "@mui/material";
import {FamilyContext} from "../context/FamilyContext";
import {Cm2cAlert} from "../Cm2cButton";
import {CourseContext} from "../context/CourseContext";
import {formatter, isMinor} from "../Helper/Validator";
import {UserContext} from "../context/UserContext";
import {SeasonContext} from "../context/SeasonContext";

function CourseForm({number = undefined, student = {privateCourses: [], workshops: []}}) {
    const [currentStudent, setCurrentStudent] = useState(student);
    const {getUser} = useContext(UserContext)
    const {family, updateStudent, getSubTotalStudent} = useContext(FamilyContext)
    const {season, loadSeason} = useContext(SeasonContext)
    const { courses, workshops } = useContext(CourseContext)

    const addCourse = (event, value) => {
        currentStudent.privateCourses = value
        updateStudent(currentStudent)
    }

    const addWorkshop = (event, value) => {
        currentStudent.workshops = value
        updateStudent(currentStudent)
    }

    const getOptionLabel = (option) => {
        if (typeof option.duration !== "undefined" &&  option.duration !== null) {
            return option.title + " - " +  option.duration;
        }

        return option.title
    }

    const handleCycleValidated = (event, value) => {
        currentStudent.cycleValidated = !currentStudent.cycleValidated
        updateStudent(currentStudent)
    }

    return (
        <Grid2 sx={{mt:1}} xs={12} sm={12} md={12} xl={12} container spacing={2}>
            <Grid2 xsOffset={9} xs={3}>
                <Cm2cAlert>
                    Sous total : <b>{formatter.format(getSubTotalStudent(currentStudent) / 100)}</b>
                </Cm2cAlert>
            </Grid2>
            <Grid2 xs={12} sm={12} md={6} xl={6} direction={"column"} container>
                <Grid2 xs={12}>
                    <Typography>
                        Cours individuels
                    </Typography>
                    <Divider />
                </Grid2>
                <Grid2 xs={12}>
                    <FormControl fullWidth variant="outlined">
                        <Autocomplete
                            multiple
                            onChange={addCourse}
                            value={currentStudent.privateCourses.filter((course) => course.season.id === season.id)}
                            isOptionEqualToValue={(value, option) => {
                                if (value["@id"] ===  option["@id"]) {
                                    return true;
                                }
                                return false;
                            }}
                            getOptionLabel={(option) => getOptionLabel(option)}
                            renderOption={(props, option) => (
                                <li id={option["@id"]} key={option["@id"]} {...props}>{option.title}&nbsp;&nbsp;
                                    { (typeof option.duration !== "undefined" &&  option.duration !== null) &&
                                        <><Chip size={"small"} color={"secondary"} label={option.duration} />&nbsp;&nbsp;</>
                                    }
                                    <Chip size={"small"} color={"default"} label={formatter.format(option.price/100)} />
                                </li>
                            )}
                            id="combo-box-demo"
                            options={courses}
                            renderInput={(params) => <TextField {...params} label="Cours individuels" placeholder={"Cherchez le cours désiré"} />}
                        />
                    </FormControl>
                </Grid2>
                { (currentStudent.privateCourses.length !== 0 && isMinor(currentStudent.birthDate)) &&
                    <Grid2 xs={12}>
                        <Cm2cAlert>
                            <AlertTitle>Information cycle 1</AlertTitle>
                            L'élève étant mineur veuillez cocher la case ci-dessous si ce dernier à déjà validé son
                            cycle 1
                        </Cm2cAlert>
                        <Grid2 xs={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={currentStudent.cycleValidated} onChange={() => handleCycleValidated()}/>}
                                                  label="Le cycle 1 est validé"/>
                            </FormGroup>
                        </Grid2>
                    </Grid2>
                }
            </Grid2>
            <Grid2 xs={12} sm={12} md={6} xl={6} direction={"column"} container>
                <Grid2 xs={12}>
                    <Typography>
                        Cours collectifs
                    </Typography>
                    <Divider />
                </Grid2>
                <Grid2 xs={12}>
                    <FormControl fullWidth variant="outlined">
                        <Autocomplete
                            multiple
                            onChange={addWorkshop}
                            isOptionEqualToValue={(value, option) => {
                                if (value["@id"] ===  option["@id"]) {
                                    return true;
                                }
                                return false;
                            }}
                            value={currentStudent.workshops.filter((course) => course.season.id === season.id)}
                            getOptionLabel={(option) => option.title + " - " +  option.duration}
                            renderOption={(props, option) => (
                                <li key={option["@id"]} {...props}>{option.title}&nbsp;&nbsp;
                                    { (typeof option.duration !== "undefined" &&  option.duration !== null) &&
                                        <Chip size={"small"} color={"secondary"} label={option.duration} />
                                    }
                                </li>
                            )}
                            id="combo-box-demo"
                            options={workshops}
                            renderInput={(params) => <TextField {...params} label="Cours collectifs / ateliers" placeholder={"Cherchez le cours désiré"} />}
                        />
                    </FormControl>
                </Grid2>
            </Grid2>
            <Grid2 xs={12} sm={12} md={12} xl={12} direction={"column"} container>
                <Grid2 xs={12}>
                    <Typography>
                        Remarques
                    </Typography>
                    <Divider />
                </Grid2>
                <Grid2 xs={12}>
                    <Cm2cAlert>
                        <AlertTitle>Remarques</AlertTitle>
                        Si vous avez des remarques sur votre inscription, notez les ci-après, elles nous permettrons de gagner du temps lors de la prise en charge de votre dossier.
                    </Cm2cAlert>
                </Grid2>
                <Grid2 xs={12}>
                    <FormControl fullWidth variant="outlined">
                        <TextField
                            value={family.remark}
                            id="family-remark"
                            label="Remarques"
                            multiline
                            rows={4}
                        />
                    </FormControl>
                </Grid2>
            </Grid2>
        </Grid2>
    );
}

export default CourseForm