import {Fragment, React, useContext, useEffect, useState} from 'react';
import '../css/Buttons.css';

import {
    AppBar,
    Avatar,
    Box,
    Button,
    Container, Divider, Drawer, Grid,
    IconButton, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
    Menu,
    MenuItem, Stack,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faClose} from "@fortawesome/free-solid-svg-icons";
import {MenuContext} from "./context/MenuContext";
import {UserContext} from "./context/UserContext";
import {publicFetchApiPlatform} from "./api/Content";
import Cm2cButton from "./Cm2cButton";
import {FamilyContext} from "./context/FamilyContext";
import {SeasonContext} from "./context/SeasonContext";

function MenuV2 ({pageId = undefined}) {
    const { menu, loadMenu } = useContext(MenuContext)
    const { season, loadSeason } = useContext(SeasonContext)
    const { getUser, logout, isConnected } = useContext(UserContext)

    const [anchorEl, setAnchorEl] = useState()
    const [subcriptionOpen, setSubscriptionOpen] = useState(false)
    const [currentUser, setCurrentUser] = useState({})
    const [openSideMenu, setOpenSideMenu] = useState(false)
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = (event) => {
        setOpenSideMenu(false);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        loadSeason();
        loadMenu();
        publicFetchApiPlatform("/subscribe/enabled").then(data => {
            if (data) {
                setSubscriptionOpen(true)
            }
        })
        setCurrentUser(getUser());
    }, [])

    const darkTheme = createTheme({
        palette: {
            mode: 'light',
            contrastText: 'rgba(0, 0, 0, 0.55)',
            primary: {
                main: '#fbf9f9ff',
            },
            contrastThreshold: 3,
        },
    });

    return (
        <ThemeProvider theme={darkTheme}>
        <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} position="sticky" color="primary">
            <Container maxWidth={"fluid"}>
                <Toolbar disableGutters>
                    <Box sx={{height: 30, display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                        <Link sx={{height: 30, display: { xs: 'none', md: 'flex' }, mr: 1 }} href={"/"}>
                            <img
                                src="https://dcfcn9te0xk7k.cloudfront.net/cm2c/cm2c-trans-sx-64c3f57320da5.png"
                                height="30"
                            />
                        </Link>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={() => setOpenSideMenu(!openSideMenu)}
                        >
                            {openSideMenu ? <FontAwesomeIcon icon={faClose} /> : <FontAwesomeIcon icon={faBars} />}
                        </IconButton>
                    </Box>
                    <Box sx={{
                        display: { xs: 'flex', md: 'none', height: 30},
                    }} component="img" alt="Pone"
                         src="https://dcfcn9te0xk7k.cloudfront.net/cm2c/cm2c-trans-sx-64c3f57320da5.png"
                    />
                    {/*<Typography*/}
                    {/*    variant="h6"*/}
                    {/*    noWrap*/}
                    {/*    component="div"*/}
                    {/*    href=""*/}
                    {/*    sx={{*/}
                    {/*        mr: 2,*/}
                    {/*        display: { xs: 'flex', md: 'none' },*/}
                    {/*        flexGrow: 1,*/}
                    {/*        fontFamily: 'monospace',*/}
                    {/*        fontWeight: 700,*/}
                    {/*        letterSpacing: '.3rem',*/}
                    {/*        color: 'inherit',*/}
                    {/*        textDecoration: 'none',*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*</Typography>*/}
                    {/*<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>*/}
                    {/*    {menu.map((page, i) => (*/}
                    {/*        <MenuItemChild key={i} menu={page} />*/}
                    {/*    ))}*/}
                    {/*</Box>*/}
                    { subcriptionOpen &&
                        <>&nbsp;&nbsp;<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
                            <Cm2cButton
                                id="basic-button"
                                aria-haspopup="true"
                                onClick={handleClick}
                                sx={{my: 2, color: '#676767', display: 'block'}}
                            >
                                <Link sx={{textDecoration: "none"}}
                                      href={"/inscription"}>Inscription {season.name}</Link>
                            </Cm2cButton>
                        </Box>
                        </>
                    }
                    {!isConnected() ?
                        <Button
                            id="basic-button"
                            aria-haspopup="true"
                            onClick={handleClick}
                            sx={{my: 2, color: '#676767', display: 'block'}}
                            >
                                <Link sx={{color: '#676767', textDecoration: "none"}}
                                    href={"/login"}>Se connecter</Link>
                        </Button>
                    :
                        <>
                        {getUser().enabled &&
                            <Stack direction={"row"}>
                                <Button
                                    id="basic-button"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    sx={{my: 2, color: '#676767', display: 'block'}}
                                >
                                    <Link sx={{color: '#676767', textDecoration: "none"}}
                                          href={"/profile/ma-reservation"}>Consulter ma réservation</Link>
                                </Button>
                                <Button
                                    id="basic-button"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    sx={{my: 2, color: '#676767', display: 'block'}}
                                >
                                    <Link onClick={() => logout()} sx={{color: '#676767', textDecoration: "none"}}>Se
                                        déconnecter</Link>
                                </Button>
                                {/*<Menu*/}
                                {/*    id="basic-menu"*/}
                                {/*    anchorEl={anchorEl}*/}
                                {/*    open={open}*/}
                                {/*    onClose={handleClose}*/}
                                {/*    MenuListProps={{*/}
                                {/*        'aria-labelledby': 'profile-button',*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <MenuItem key={1} ><Link sx={{color: '#676767', textDecoration: "none"}} href={"/logout"}>Editer mon profil</Link></MenuItem>*/}
                                {/*    <MenuItem key={1} ><Link sx={{color: '#676767', textDecoration: "none"}} href={"/profile/ma-reservation"}>Consulter ma réservation</Link></MenuItem>*/}
                                {/*    <Divider />*/}
                                {/*    <MenuItem onClick={() => logout()} color={"error"} key={1} ><Link sx={{color: '#676767', textDecoration: "none"}}>Se déconnecter</Link></MenuItem>*/}
                                {/*</Menu>*/}
                            </Stack>
                        }
                        </>
                    }
                </Toolbar>
            </Container>
        </AppBar>
        <DrawerMenu open={openSideMenu}  menu={menu} handeClose={handleCloseMenu} />
        </ThemeProvider>
    );
}

export default MenuV2

function MenuItemChild ({menu = undefined}) {
    const [anchorEl, setAnchorEl] = useState()
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu-' + menu.item.page.slug : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{my: 2, color: '#676767', display: 'block'}}
            >
                {menu.item.label}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button-' + menu.item.page.slug,
                }}
            >
                {menu.child.map((item, i) =>
                    <MenuItem key={i}  onClick={handleClose}><Link sx={{color: '#676767', textDecoration: "none"}}
                                    href={"/" + item.page.slug}>{item.label}</Link></MenuItem>
                )}
            </Menu>
        </>
    );
}

function DrawerMenu ({menu = undefined, open = false, handeClose = () => {}}) {
    return (
        <Drawer sx={{
            width: 240,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
        }} open={open} >
            <Toolbar />
            <Box sx={{ overflow: 'auto' }}>
                <List>
                    {menu.map((item, index) => (
                        <Fragment key={index}>
                            <ListItem key={index} disablePadding>
                                <ListItemButton>
                                    <ListItemText style={{textTransform: "uppercase", color: "#939393"}} primary={item.item.label} />
                                </ListItemButton>
                            </ListItem>
                            {item.child.map((item, index) => (
                                <ListItemButton
                                    key={item.label}
                                    sx={{ py: 0, minHeight: 32, color: '#939393' }}
                                >
                                    <ListItemText
                                        primary={item.label}
                                        primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                                    />
                                </ListItemButton>
                            ))}
                        </Fragment>
                    ))}
                </List>
            </Box>
        </Drawer>
    );
}