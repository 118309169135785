export const IMAGE_WIDTHS = [
    {key: 25,value: "25%"},
    {key: 50,value: "50%"},
    {key: 75,value: "75%"},
    {key: 100,value: "100%"}
]

export const IMAGE_ALIGNMENTS = [
    {key: "left",value: "gauche"},
    {key: "center",value: "centré"},
    {key: "right",value: "droite"},
]