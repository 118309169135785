import React from 'react';
import {useState, useEffect} from "react";
import {Col, Row, Form, } from "react-bootstrap";

function RangeRow({label = "", value, onChange, min = 0, max = 5}) {
    const [currentValue, setCurrentValue] = useState(value);

    useEffect(() => {
        // updateMediaContent(lineId, col.id, media, width, padding, alignment)
    }, [])

    return (
        <Col xs={12}>
            <Form.Group as={Row}>
                <Form.Label column sm="4">{label}</Form.Label>
                <Col sm="8">
                    <Form.Range
                        value={currentValue}
                        min={min}
                        max={max}
                        onChange={e => {
                            setCurrentValue(e.target.value)
                            onChange(e)
                        }}
                    />
                </Col>
            </Form.Group>
        </Col>
    );
}

export default RangeRow