import React from 'react';
import {useState ,useContext, useEffect} from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
    Box,
    Divider,
    Tab, Tabs,
    Typography
} from "@mui/material";
import {FamilyContext} from "../context/FamilyContext";
import CourseForm from "./CourseForm";
import {CourseContext} from "../context/CourseContext";

function Courses() {
    const [currentTab, setCurrentTab] = useState(0);
    const { students } = useContext(FamilyContext)
    const { loadCourses } = useContext(CourseContext)

    useEffect(() => {
        loadCourses()
    }, [])

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return (
        <Grid2 sx={{p:2}} sm={12} xs={12}>
            <Typography variant={"h6"}>Cours et ateliers</Typography>
            <Divider />
            <Tabs variant="fullWidth" value={currentTab} onChange={handleChange} aria-label="basic tabs example">
                { students.filter((student) => !student.disableForSeason).map((student, i) =>
                    <Tab key={i} label={student.firstname + " " + student.lastname} />
                )}
            </Tabs>
            { students.filter((student) => !student.disableForSeason).map((student, i) =>
                <TabPanel key={i} value={currentTab} index={i}>
                    <CourseForm student={student} />
                </TabPanel>
            )}
        </Grid2>
    );
}

export default Courses