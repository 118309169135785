import {Fragment, React, useContext, useEffect, useState} from "react";
import '../css/RowTemplate.css';
import PageWrapper from "./PageWrapper";
import {
    AlertTitle,
    Box,
    Chip,
    Divider,
    Paper,
    Stack,
    Step,
    StepLabel,
    Stepper, Table, TableBody, TableCell, TableContainer, TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {UserContext} from "./context/UserContext";
import {teal} from "@mui/material/colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog, faEnvelope, faGraduationCap, faMoneyBill, faPhone, faUserTie} from "@fortawesome/free-solid-svg-icons";
import {getCivility, getCourseType} from "./Helper/Civility";
import {Cm2cAlert} from "./Cm2cButton";
import {formatter} from "./Helper/Validator";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import {Title} from "./Helper/Title";
import {FamilyContext} from "./context/FamilyContext";
import {fetchApiPlatform} from "./api/Content";
import {SeasonContext} from "./context/SeasonContext";

function Profile() {
    const { loadStudents, setFamily, getSubTotalStudent, family, students } = useContext(FamilyContext)
    const { loadSeason, season } = useContext(SeasonContext)

    useEffect(() => {
        fetchApiPlatform("/me").then(data => {
            setFamily(data);
            loadSeason();
            loadStudents(data.id)
        }).catch(e => {
            console.log()
        })
    }, [])

    const getTotalInvoice = () => {
        let total = 0
        students.map((student) => {
            total = total + getSubTotalStudent(student)
        })

        return total + 2200
    }

    return (
        <PageWrapper>
            <Grid2 style={{backgroundColor: "white"}} xs={12} sm={12} smOffset={0} xsOffset={0} md={12} mdOffset={0} xl={10} xlOffset={1} container spacing={4}>
                <Title>Ma réservation {season.name}</Title>
                <Grid2 sm={12} xs={12} xl={4} md={4} >
                    <Paper elevation={4}>
                        <Grid2 xs={12} >
                            <Typography style={{color: teal[700], fontSize: "19px"}}>
                                <FontAwesomeIcon style={{color: teal[500], padding: "5px",verticalAlign: "middle", fontSize:"25px"}}  icon={faUserTie} />
                                <b><i>{getCivility(family.civility)} {family.firstname} {family.lastname}</i></b>
                            </Typography>
                            <Divider />
                        </Grid2>
                        <Grid2 xs={12}>
                            <Typography>
                                <FontAwesomeIcon style={{padding: "5px",verticalAlign: "middle", fontSize:"20px"}}  icon={faEnvelope} />
                                {family.email}
                            </Typography>
                            <Typography>
                                <FontAwesomeIcon style={{padding: "5px",verticalAlign: "middle", fontSize:"20px"}}  icon={faPhone} />
                                {family.phone}
                            </Typography>
                            <Typography sx={{mt:1}}>
                                {family.address} {family.postalCode}<br />
                                {family.city}
                            </Typography>
                        </Grid2>
                    </Paper>
                </Grid2>
                <Grid2 sm={12} xs={12} xl={5} md={5} >
                    <Paper elevation={4}>
                        <Grid2 xs={12} >
                            <Typography style={{color: teal[700], fontSize: "19px"}}>
                                <FontAwesomeIcon style={{color: teal[500], padding: "5px",verticalAlign: "middle", fontSize:"25px"}}  icon={faMoneyBill} />
                                <b><i>Récapitulatif</i></b>
                            </Typography>
                            <Divider />
                        </Grid2>
                        <Grid2 xs={12}>
                            { students.length === 0 &&
                                <Typography>Vous n'avez pas de réservation actuellement...</Typography>
                            }
                            <TableContainer>
                                <Table size="small">
                                    <TableBody>
                                        { students.filter((student) => !student.disableForSeason).map((student, i) =>
                                            <Fragment key={i}>
                                            <TableRow
                                                key={i}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell><b>{student.fullname}</b>
                                                    { student.cycleValidated && <>&nbsp;&nbsp;<Chip size={"small"} color={"success"} label="Cycle 1 validé"></Chip></>
                                                }</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                            { student.privateCourses.map((course, i) =>
                                                (course.season.id === season.id) &&
                                                    <TableRow
                                                        key={i}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{getCourseType(course.type)}</TableCell>
                                                        <TableCell component="th" scope="row">{course.title}</TableCell>
                                                        <TableCell>{course.duration}</TableCell>
                                                        <TableCell>{formatter.format(course.price/100)}</TableCell>
                                                    </TableRow>
                                            )}
                                                { student.workshops.map((course, i) =>
                                                    (course.season.id === season.id) &&
                                                        <TableRow
                                                            key={i}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell>{getCourseType(course.type)}</TableCell>
                                                            <TableCell component="th" scope="row">{course.title}</TableCell>
                                                            <TableCell>{course.duration}</TableCell>
                                                            <TableCell>{formatter.format(course.price/100)}</TableCell>
                                                        </TableRow>
                                                )}
                                                <TableRow
                                                    key={"total_student" + student.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell>Total èlève</TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell>{formatter.format(getSubTotalStudent(student)/100)}</TableCell>
                                                </TableRow>
                                            </Fragment>
                                        )}
                                        { students.length !== 0 &&
                                            <>
                                            <TableRow
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell><b>Cotisation</b></TableCell>
                                                <TableCell component="th" scope="row">-</TableCell>
                                                <TableCell>-</TableCell>
                                                <TableCell>{formatter.format(2200 / 100)}</TableCell>
                                            </TableRow>
                                            <TableRow
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell><b>TOTAL</b></TableCell>
                                                <TableCell component="th" scope="row"></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell><b>{formatter.format(getTotalInvoice()/100)}</b></TableCell>
                                            </TableRow>
                                            </>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid2>
                    </Paper>
                </Grid2>
                <Grid2 sm={12} xs={12} xl={3} md={3}>
                    <Paper style={{height: "100%"}} elevation={4}>
                        <Grid2 xs={12} >
                            <Typography style={{color: teal[700], fontSize: "19px"}}>
                                <FontAwesomeIcon style={{color: teal[500], padding: "5px",verticalAlign: "middle", fontSize:"25px"}}  icon={faCog} />
                                <b><i>Actions</i></b>
                            </Typography>
                            <Divider />
                        </Grid2>
                        <Grid2  p={2} container spacing={2} >
                            <Grid2 xs={12}>
                                <Button color={"primary"} fullWidth variant={"outlined"}>Modifier mon profil</Button>
                            </Grid2>
                            <Grid2 xs={12}>
                                <Button color={"secondary"} fullWidth variant={"outlined"}>Demande de contact</Button>
                            </Grid2>
                            <Grid2 xs={12}>
                                <Button color={"warning"} fullWidth variant={"outlined"}>Se déconnecter</Button>
                            </Grid2>
                        </Grid2>
                    </Paper>
                </Grid2>
            </Grid2>
            <Grid2 style={{backgroundColor: "white"}} xs={12} xsOffset={0} md={10} mdOffset={1} container spacing={4}>
                <Title>Éleve(s) et cours</Title>
                {students.length === 0 &&
                    <Grid2 sm={12} xs={12} xlOffset={4} xl={4} mdOffset={4} md={4} >
                        <Cm2cAlert>
                            Vous n'avez pas de réservation actuellement...
                        </Cm2cAlert>
                    </Grid2>
                }
                {students.map((student, i) =>
                    <Grid2 key={i} sm={12} xs={12} xl={6} md={6} >
                        <Paper elevation={4}>
                            <Typography style={{color: teal[700], fontSize: "19px"}}>
                                <FontAwesomeIcon style={{color: teal[500], padding: "5px",verticalAlign: "middle", fontSize:"25px"}}  icon={faGraduationCap} />
                                <b><i>{getCivility(student.civility)} {student.firstname} {student.lastname}</i></b>
                            </Typography>
                            <Divider />
                            <Typography sx={{p:1}}>
                                {dayjs(student.birthDate).format('DD/MM/YYYY')}
                                { student.cycleValidated &&
                                    <>&nbsp;&nbsp;<Chip size={"small"} color={"success"} label="Cycle 1 validé"></Chip></>
                                }
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table size="small">
                                    <TableBody>
                                        { student.courses.map((course, i) =>
                                            <TableRow
                                                key={i}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>{getCourseType(course.type)}</TableCell>
                                                <TableCell component="th" scope="row">{course.title}</TableCell>
                                                <TableCell>{course.duration}</TableCell>
                                                <TableCell>{formatter.format(course.price/100)}</TableCell>
                                            </TableRow>
                                        )}
                                        <TableRow
                                            key={"total_student"}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>Total èlève</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>{formatter.format(getSubTotalStudent(student)/100)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid2>
                )}
            </Grid2>
        </PageWrapper>
    );
}

export default Profile