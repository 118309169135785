export const getContents = async () => {
    return await fetch("http://cm2c.local/api/contents-rendered").then((response) =>
        response.json().then((data) => data["hydra:member"])
    )
}

export const getContent = async (id) => {
    return await fetch("http://cm2c.local/api/contents/" + id).then((response) =>
        response.json().then((data) => data)
    )
}

export const publicFetchApiPlatform = async (url, method = 'GET', body = {}) => {
    const defaultOptions = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        }
    };

    if (method != 'GET' && method != 'DELETE') defaultOptions.body = JSON.stringify(body)

    return await fetch(process.env.REACT_APP_API_HOST + url, defaultOptions)
        .then((res) => {
            if (!res.ok) {
                let error = res.statusText;
                throw Error(error);
            }
            return method == 'DELETE' ? true : res.json()
        })
        .catch((err) => {
            throw err
        });
}


export const fetchApiPlatform = async (url, method = 'GET', body = {}) => {
    const defaultOptions = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    };

    if (method !== 'GET' && method !== 'DELETE') defaultOptions.body = JSON.stringify(body)

    return await fetch(process.env.REACT_APP_API_HOST + url, defaultOptions)
        .then((res) => {
            if (!res.ok) {
                if (res.status === 401) {
                    localStorage.removeItem("token")
                    localStorage.removeItem("user")
                    window.location.href = "/login"
                }
            }
            return method === 'DELETE' ? true : res.json()
        })
        .catch((err) => {
            throw err
        });
}

export const postApi = async (url, method = 'POST', body = {}) => {
    const requestOptions = {
        method: method,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    };

    return await fetch(process.env.REACT_APP_API_HOST + url, requestOptions)
}


export const publicPostApi = async (url, method = 'POST', body = {}) => {
    const requestOptions = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + localStorage.getItem("token"),
        },
        body: JSON.stringify(body)
    };

    return await fetch(process.env.REACT_APP_API_HOST + url, requestOptions)
}


