import {React} from 'react';
import {Image} from "react-bootstrap";

function ImageContent({lineId, col}) {

    const getAlignment = () => {
        switch (col.alignment) {
            case 'left':
                return "";
            case 'right':
                return "float-end";
            case 'center':
                return "mx-auto d-block";
                break;
            default:
                return "";
        }
    }

        return (
            <Image fluid
                   className={getAlignment() +" p-" + col.padding + " w-" + col.width + " rounded rounded-" + col.rounded}
                   src={col.image}
                   rounded={true}
            />
        );
}

export default ImageContent