import React from 'react';
import {useState, useContext, useEffect} from "react";

import '../../css/Text.css';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import {RawContext} from "../context/RawContext";
import {PageContext} from "../context/PageContext";

function Text({col, lineId, preview = false}) {
    const { displayRaw, setDisplayRaw } = useContext(RawContext)
    const { updateLineContent, getContentHtml } = useContext(PageContext)

    const parseCkEditorHtml = (dataHtml) => {
        const parseTable3 = dataHtml.replace("<table>", "<table class='table'>")
        const parseTable2 = parseTable3.replace("<tr>", "<tr class='table-primary'>")

        return parseTable2.replace("<img", "<img class='img-fluid rounded-2'");
    }

    return (
        <>
            { (!displayRaw && !preview) ?
                    <CKEditor
                        config={{
                            image: {
                                toolbar: ['imageResize']
                            },
                            heading: {
                                options: [
                                    {
                                        model: 'heading2',
                                        view: {
                                            name: 'h2',
                                            classes: [ 'redCm2c' ]
                                        },
                                        title: 'Titre 2 - Rouge',

                                        // It needs to be converted before the standard 'heading2'.
                                        converterPriority: 'high'
                                    },
                                    {
                                        model: 'heading3',
                                        view: {
                                            name: 'h3',
                                            classes: [ 'redCm2c' ]
                                        },
                                        title: 'Titre 3 - Rouge',

                                        // It needs to be converted before the standard 'heading2'.
                                        converterPriority: 'high'
                                    },
                                    {
                                        model: 'paragraphLead',
                                        view: {
                                            name: 'p',
                                            classes: [ 'lead' ]
                                        },
                                        title: 'Paragraph lead',
                                        // It needs to be converted before the standard 'heading2'.
                                        converterPriority: 'high'
                                    },
                                    {
                                        model: 'paragraph',
                                        view: {
                                            name: 'p',
                                            classes: [ 'redCm2c' ]
                                        },
                                        title: 'Paragraph',
                                        // It needs to be converted before the standard 'heading2'.
                                        converterPriority: 'high'
                                    },
                                    {
                                        model: 'buttonLink',
                                        view: {
                                            name: 'a',
                                            classes: [ 'redCm2cButton btn btn-primary' ]
                                        },
                                        title: 'Bouton lien',
                                        // It needs to be converted before the standard 'heading2'.
                                        converterPriority: 'high'
                                    }
                                ]
                            }
                        }}
                        editor={ Editor }
                        data={getContentHtml(lineId, col.id)}
                        onReady={ editor => {
                            // You can store the "editor" and use when it is needed.
                        } }
                        onChange={ ( event, editor ) => {
                            updateLineContent(lineId, col.id, parseCkEditorHtml(editor.getData()))
                        } }
                        onBlur={ ( event, editor ) => {
                            console.log( 'Blur.', editor );
                        } }
                        onFocus={ ( event, editor ) => {
                            console.log( 'Focus.', editor );
                        } }
                    />
                :
                <div dangerouslySetInnerHTML={{__html: getContentHtml(lineId, col.id).replace(/(<? *script)/gi, 'illegalscript')}} />
            }
        </>
    );
}

export default Text