import React from 'react';
import {useState, useEffect, useContext} from "react";
import {Row, Container, Form, Button} from "react-bootstrap";
import {fetchApiPlatform} from "../api/Content";
import RangeRow from "./fields/RangeRow";
import ButtonsRow from "./fields/ButtonsRow";
import {IMAGE_ALIGNMENTS, IMAGE_WIDTHS} from "./menuConfigs";
import {PageContext} from "../context/PageContext";

function ImageMenu({lineId, col}) {
    const [medias, setMedias] = useState([])
    const [colId] = useState(col.id)
    const [media, setMedia] = useState(col.image)
    const [width, setWidth] = useState(col.width)
    const [padding, setPadding] = useState(col.padding)
    const [rounded, setRounded] = useState(col.rounded)
    const [alignment, setAligment] = useState(col.alignment)
    const { updateMediaContent } = useContext(PageContext)

    useEffect(() => {
        updateMediaContent(lineId, colId, media, width, padding, alignment, rounded)
    }, [media, width, padding, alignment, rounded])

    useEffect(() => {
        fetchApiPlatform("/media").then(data => {
            setMedias(data["hydra:member"])
        })
    }, [])

    return (
        <Container>
            <Row>
                <Form.Group controlId="image">
                    <Form.Label>Média</Form.Label>
                    <Form.Select  onChange={e => setMedia(e.target.value)} value={media}>
                        { (medias.length !== 0) &&
                             medias.map((media, i) =><option key={i} value={media.fullUrl} >{media.name}</option>)
                        }
                    </Form.Select>
                </Form.Group>
            </Row>
            <Row>
                <ButtonsRow values={IMAGE_WIDTHS} value={width} onChange={e => setWidth(e.target.value)} label={"Largeur"} />
                <ButtonsRow values={IMAGE_ALIGNMENTS} value={width} onChange={e => setAligment(e.target.value)} label={"Alignement"} />
                <RangeRow min={0} max={5} label={"Marge"} value={padding} onChange={e => setPadding(e.target.value)} />
                <RangeRow min={0} max={5} label={"Arrondi"} value={rounded} onChange={e => setRounded(e.target.value)} />
            </Row>
        </Container>
    );
}

export default ImageMenu