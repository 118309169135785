import React from 'react';
import {useContext, useEffect, useState} from "react";
import '../css/RowTemplate.css';
import PageWrapper from "./PageWrapper";
import {AlertTitle, Paper} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Cm2cAlert} from "./Cm2cButton";
import {publicFetchApiPlatform} from "./api/Content";
import {AlertContext} from "./context/AlertContext";
const steps = ['Famille', 'Éleve(s)', 'Cours', 'Récapitulatif'];

function Subscription() {
    const { setMessage, setOpen, setSeverity } = useContext(AlertContext)

    useEffect(() => {
        publicFetchApiPlatform("/subscribe/enabled").then(data => {
            if (data) {
                window.location.href = "/inscription";
            }
        }).catch(e => {
            setMessage(e.message)
            setSeverity("error")
            setOpen(true)
        });
    }, [])

    return (
        <PageWrapper>
            <Grid2 container >
                <Grid2 xs={12} sm={12} md={8} mdOffset={2} xl={8} xlOffset={2} sx={{m:1, p:2}} component={Paper} >
                    <Cm2cAlert>
                        <AlertTitle>Les inscriptions sont actuellement fermées</AlertTitle>
                        Il n'est plus possible de s'inscrire en lignes pour cette saison<br/>
                        Si vous souhaitez effectuer une inscription en cours d'années, vous pouvez contacter le centre musical au....
                    </Cm2cAlert>
                </Grid2>
            </Grid2>
        </PageWrapper>
    );
}

export default Subscription