import React from 'react';
import {useContext, useEffect} from "react";
import '../css/Pager.css';

import {Container, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import LineContent from "./LineContent";
import {PageContext} from "./context/PageContext";
import {RawContext} from "./context/RawContext";
import PageWrapper from "./PageWrapper";

function Page() {
    const { page, loadPage, setPreview } = useContext(PageContext)
    const { setDisplayRaw } = useContext(RawContext)
    let { slug } = useParams();

    useEffect(() => {
        try {
            loadPage(slug);
        } catch (e) {
            window.location.href = "/";
        }

        setDisplayRaw(true)
        setPreview(false)
    }, [])

    return (
        <PageWrapper>
            <Container fluid={page.containerWidth} >
                <Container id="content" fluid>
                    {
                        page.template.map((line, i) => (
                            <LineContent line={line} key={line.id} />
                        ))
                    }
                </Container>
            </Container>
        </PageWrapper>
    );
}

export default Page