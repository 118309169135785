import React from 'react';
import {Container} from "react-bootstrap";
import '../css/RowTemplate.css';
import Menu from "./Menu";
import PageHeader from "./PageHeader";
import MenuV2 from "./MenuV2";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Box} from "@mui/material";
import Footer from "./Footer";

function PageWrapper({children}) {
    return (
        <>
            <Box sx={{ width: '100%' }}>
                <MenuV2 />
                <PageHeader />
                {children}
            </Box>
            <Footer />
        </>
    );
}

export default PageWrapper