import React, {Fragment} from 'react';
import {useState ,useContext, useEffect} from "react";
import {frFR} from '@mui/x-date-pickers/locales';
import Grid2 from "@mui/material/Unstable_Grid2";
import {PageContext} from "../context/PageContext";
import {
    Checkbox,
    Divider, FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText, InputLabel, MenuItem, Select,
    Stack,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {FamilyContext} from "../context/FamilyContext";
import {isEmail, isPhone, uniqid} from "../Helper/Validator";
import Cm2cButton from "../Cm2cButton";
import {faAdd} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import StudentForm from "./StudentForm";
import {UserContext} from "../context/UserContext";

const MAX_STUDENTS = 8;

function Family() {
    const [phoneValid, setPhoneValid] = useState(true);

    const [emailValid, setEmailValid] = useState(true);
    const [emailError, setEmailError] = useState("");
    const [isReferentStudent, setIsReferentStudent] = useState(false);

    const { family, loadStudents, loadFamily, deleteStudent, students, setStudents } = useContext(FamilyContext)
    const { getUser } = useContext(UserContext)

    useEffect(() => {
        let referentStudent = students.find((student) => student.isReferent === true)
        if (typeof referentStudent !== "undefined") {
            setIsReferentStudent(true)
        } else  {
            setIsReferentStudent(false)
        }

    }, [])

    function checkEmail(event) {
        if (isEmail(event.target.value)) {
            setEmailValid(true);
            setEmailError("")
        } else {
            setEmailValid(false);
            setEmailError("Format d'email invalide")
        }
    }

    function checkPhone(event) {
        if(isPhone(event.target.value)) {
            setPhoneValid(true);
        } else {
            setPhoneValid(false);
        }
    }

    function addStudent(isReferent = false) {
        let studentsTmp = [...students];
        let fami = "/api/users/" + getUser().id;
        if (isReferent) {
            let referentStudent = students.find((student) => student.isReferent === true)
            if (typeof referentStudent !== "undefined") {
                deleteStudent(referentStudent)
                return
            }
            studentsTmp.push({
                id: uniqid(),
                firstname: family.firstname,
                lastname: family.lastname,
                civility: family.civility,
                birthDate: family.birthDate,
                disableForSeason: false,
                phone: family.phone,
                email: family.email,
                isReferent: true,
                cycleValidated: false,
                family: fami,
                privateCourses: [],
                courses: [],
                workshops: []
            })
        } else {
            studentsTmp.push({
                id: uniqid(),
                firstname: "",
                lastname: "",
                civility: "",
                family: fami,
                birthDate: "",
                disabled: false,
                disableForSeason: false,
                cycleValidated: false,
                isReferent: isReferent,
                privateCourses: [],
                workshops: [],
                courses: [],
            })
        }

        setStudents(studentsTmp);
    }

    return (
        <Grid2 sx={{p:2}} sm={12} xs={12}>
            <Typography variant={"h6"}>Éleve(s)</Typography>
            <FormGroup>
                <FormControlLabel checked={isReferentStudent} control={<Checkbox onChange={() => {
                    setIsReferentStudent(!isReferentStudent);
                    addStudent(true)
                }}
                />} label="Le référent légal est un élève" />
            </FormGroup>
            <Divider />
            <Grid2 xs={12} sm={12} md={12} xl={12} container spacing={2}>
                <Grid2 sx={{mt:1}} xs={6}>
                        <Cm2cButton disabled={(students.length >= MAX_STUDENTS)} onClick={() => addStudent()} ><FontAwesomeIcon icon={faAdd} />&nbsp;Ajouter un élève</Cm2cButton>
                </Grid2>
            </Grid2>
            <Grid2 sx={{mt:1}} xs={12}>
                <Divider />
            </Grid2>
            {students.map((student, i) =>
                <Fragment key={student.id}>
                    <StudentForm number={student.id} key={student.id} student={student} />
                    <Divider sx={{mt:2}} />
                </Fragment>
            ) }
        </Grid2>
    );
}

export default Family