import {useState, createContext} from "react";
import {fetchApiPlatform, publicFetchApiPlatform} from "../api/Content";

export const MenuContext = createContext()

export const MenuProvider = ({ children }) => {
    const [menu, setMenu] = useState([]);

    const loadMenu = () => {
        publicFetchApiPlatform("/menu").then(data => {
            setMenu(data["hydra:member"])
        })
    }

    return (
        <MenuContext.Provider value={{ loadMenu, menu }}>
            {children}
        </MenuContext.Provider>
    )
}