import React from 'react';
import {useState, useContext} from "react";
import {publicPostApi} from "./api/Content";
import {UserContext} from "./context/UserContext";
import PageWrapper from "./PageWrapper";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
    Button,
    Divider,
    FormControl,
    Input,
    InputAdornment,
    InputLabel, Link,
    Paper, Stack,
    TextField,
    Typography
} from "@mui/material";
import Cm2cButton, {Cm2cLoadingButton} from "./Cm2cButton";
import {Image} from "@mui/icons-material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKey, faUser} from "@fortawesome/free-solid-svg-icons";

function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { login, signIn } = useContext(UserContext)

    return (
        <PageWrapper>
            <Grid2 container spacing={2} >
                <Grid2 xlOffset={4} xl={4} mdOffset={3} md={6} sm={12} xs={12}>
                    <Paper elevation={2}>
                        <Grid2 container
                               p={5}
                               direction="row"
                               display="flex"
                               justifyContent="center">
                            <Grid2 xs={12}>
                                <img
                                    src="https://dcfcn9te0xk7k.cloudfront.net/cm2c/cm2c-label-64c6bd9f8cf2b.png"
                                    className="d-inline-block align-top p-0"
                                    width="100%"
                                />
                            </Grid2>
                            <Grid2 xs={12} mt={2}>
                                <FormControl fullWidth variant="outlined">
                                    <TextField
                                        type="email"
                                        required
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        id="lastname-input" label="Email" variant="outlined"
                                    />
                                </FormControl>
                            </Grid2>
                            <Grid2 xs={12} mt={2}>
                                <FormControl fullWidth variant="standard">
                                    <TextField
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        type={"password"}
                                        required
                                        id="lastname-input" label="Mot de passe" variant="outlined"
                                    />
                                </FormControl>
                            </Grid2>
                            <Grid2 mt={2} xs={12}>
                                <Stack direction={"row"} justifyContent={"space-between"} >
                                    <Cm2cButton disabled={false} onClick={() => signIn(username, password, "/inscription")} >Se connecter</Cm2cButton>
                                    <Typography style={{color: ""}}>
                                        <i>
                                            <Link sx={{color: '#676767', textDecoration: "none"}}
                                                  href={"/reset-password/ask"}>Mot de passe oublié</Link>
                                        </i>
                                    </Typography>
                                </Stack>
                            </Grid2>
                        </Grid2>
                    </Paper>
                </Grid2>
            </Grid2>
        </PageWrapper>
    );
}

export default Login