import React from 'react';
import {useState, useEffect} from "react";
import {Col, Row, Form, ButtonGroup, Button} from "react-bootstrap";

function ButtonRow({label = "", values= [], value, onChange, min = 0, max = 5}) {
    const [currentValue, setCurrentValue] = useState(value);

    useEffect(() => {
        // updateMediaContent(lineId, col.id, media, width, padding, alignment)
    }, [])

    return (
        <Col xs={12}>
            <Form.Group as={Row}>
                <Form.Label column sm="4">{label}</Form.Label>
                <Col sm="8">
                    <ButtonGroup  size="sm">
                        { values.map((buttonValue, i) => <Button key={i} value={buttonValue.key}
                            onClick={e => {
                                setCurrentValue(e.target.value)
                                onChange(e)
                            }}
                        >{buttonValue.value}</Button>)}
                    </ButtonGroup>
                </Col>
            </Form.Group>
        </Col>
    );
}

export default ButtonRow