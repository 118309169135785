import React from 'react';
import {Col, Row} from "react-bootstrap";
import '../../css/Line.css';
function LineSelector({configs = [], selected = false, handleClick}) {

    const handleChange = (configs) => {
        handleClick(configs)
    }

    const isSelected = (JSON.stringify(selected) ===  JSON.stringify(configs)) ? "box-selected" : "";

    return (
        <Row onClick={() => handleChange(configs)} className={"box " + isSelected} style={{minHeight: '45px'}}>
            {configs.map((col, index) =>
                <Col key={index} className={"p-2"} style={{textAlign: 'center', border: '1px solid black'}} lg={col}>{col}</Col>
            )}
        </Row>
    );
}

export default LineSelector