import React from 'react';
import '../css/RowTemplate.css';
import {Box} from "@mui/material";

function PageHeader({children}) {
    return (
        <Box
            style={{
                backgroundImage: 'url("https://dcfcn9te0xk7k.cloudfront.net/cm2c/back-new-64c6a05681e10.jpg")',
                backgroundSize: 'cover'
            }}
            sx={{
                height: 100,
                width: "100%",
            }}
        />
    );
}

export default PageHeader