import {useState, createContext} from "react";
import {publicFetchApiPlatform} from "../api/Content";

export const SeasonContext = createContext()

export const SeasonProvider = ({ children }) => {
    const [season, setSeason] = useState({});

    const loadSeason = async () => {
        if (typeof season.id === "undefined") {
             publicFetchApiPlatform("/current-season").then(data => {
                setSeason(data)
            })
        }
    }

    return (
        <SeasonContext.Provider value={{season, loadSeason}}>
            {children}
        </SeasonContext.Provider>
    )
}