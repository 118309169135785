import {useState, createContext, useContext} from "react";
import {fetchApiPlatform, publicPostApi, publicFetchApiPlatform} from "../api/Content";
import {AlertContext} from "./AlertContext";

export const PageContext = createContext()

const DEFAULT_TEXT_CONTENT = "" +
    "<h2 class='redCm2c'>Bienvenue sur le site du cm2c !</h2>" +
    "<p>Le Centre Musical et Culturel de Chalamont (CM2C) est une association loi 1901. Il a pour but d'offrir un enseignement de qualité avec une équipe de professeurs diplômés. Il met en valeur les compétences et les envies de chacun, du cours individuel aux ateliers et grands ensembles que sont la batucada\"Histoire de Pô\" (percussions brésiliennes) et le big band jazz \"Dim Dam Domb\"." +
    "</p>"
;

export const PageProvider = ({ children }) => {
    const { setMessage, setDisplayToast, setVariant, setTitle } = useContext(AlertContext)

    const [currentIndex, setCurrentIndex] = useState(0);
    const [preview, setPreview] = useState([]);

    const [page, setPage] = useState({
        "title" : "",
        "template": [],
        "containerWidth": "fluid",
    });

    const loadPage = (slug) => {
        publicFetchApiPlatform("/pages/" + slug).then(data => {
            setPage(data)
        })
    }

    const updateContent = (lineId, colId, contentType, backgroundColor, padding, rounded) => {
        let pageCopy = {...page};
        let tmpLines = [...page.template];

        let lineToModify = page.template.find(line => lineId === line.id)
        let LineToModifyIndex = page.template.findIndex(line => line.id === lineId)

        let colToModify = lineToModify.cols.find(col => colId === col.id)
        let colToModifyIndex = lineToModify.cols.findIndex(col => colId === col.id)

        colToModify.content.type = contentType;
        colToModify.content.backgroundColor = backgroundColor;
        colToModify.content.padding = padding;
        colToModify.content.rounded = rounded;

        lineToModify.cols.splice(colToModifyIndex, 1, colToModify)

        if (LineToModifyIndex !== -1) {
            tmpLines.splice(LineToModifyIndex, 1, lineToModify)
        }

        pageCopy.template = tmpLines;
        setPage(pageCopy)
    }

    const updateMediaContent = (lineId, colId, image, width, padding, alignment, rounded) => {
        let pageCopy = {...page};
        let tmpLines = [...page.template];

        let lineToModify = page.template.find(line => lineId === line.id)
        let colToModify = lineToModify.cols.find(col => colId === col.id)

        let colToModifyIndex = lineToModify.cols.findIndex(col => colId === col.id)
        let LineToModifyIndex = page.template.findIndex(line => line.id === lineId)

        colToModify.image = image;
        colToModify.width = width;
        colToModify.padding = padding;
        colToModify.alignment = alignment;
        colToModify.rounded = rounded;

        if (typeof colToModify.image === "undefined") {
            colToModify = {...colToModify, width: "100"}
            colToModify = {...colToModify, image: "https://dcfcn9te0xk7k.cloudfront.net/cm2c/back-63ee8bf6b8e6b.jpg"}
            colToModify = {...colToModify, padding: "0"}
            colToModify = {...colToModify, rounded: "0"}
        }

        lineToModify.cols.splice(colToModifyIndex, 1, colToModify)

        if (LineToModifyIndex !== -1) {
            tmpLines.splice(LineToModifyIndex, 1, lineToModify)
        }

        pageCopy.template = tmpLines;
        setPage(pageCopy)
    }

    const removeLine = (lineToDelete) => {
        let pageCopy = {...page};
        let tmpLines = [...page.template];

        let LineToModifyIndex = page.template.findIndex(line => line.id === lineToDelete.id)

        tmpLines.splice(LineToModifyIndex, 1)

        const linesCopy = tmpLines.map((line, i) => {
            let lineCopy = {...line}
            lineCopy.id = i+1

            return lineCopy;
        });

        pageCopy.template = linesCopy;
        setPage(pageCopy)
    }

    const addLine = (template) => {
        let pageCopy = {...page};
        let templateCopy = [...pageCopy.template];

        let indexOne = currentIndex + 1;
        setCurrentIndex(currentIndex + 1);

        let newLine = {}
        newLine.id = pageCopy.template.length + 1;
        newLine.cols = [];
        template.map((col, i) => {
            newLine.cols.push({id:i, col: col, content: {padding:0, type:"text", rounded: 0}, contentHtml: DEFAULT_TEXT_CONTENT})
        })

        templateCopy.push(newLine)
        pageCopy.template = templateCopy;

        setPage(pageCopy);
    }

    const initPage = (page) => {
        setPage(page)
    }

    const updateLine = (lineToUpdate, selectedTemplate) => {
        let pageCopy = {...page};
        let templateCopy = [...pageCopy.template];

        if (typeof lineToUpdate === "undefined") {
            return
        }

        let lineToModify = {...page.template.find(line => lineToUpdate.id === line.id)}
        let LineToModifyIndex = page.template.findIndex(line => lineToUpdate.id === line.id)

        lineToModify.cols.map((col, i) => col.col = selectedTemplate[i])

        templateCopy.splice(LineToModifyIndex, 1, lineToModify)

        pageCopy.template = templateCopy;
        setPage(pageCopy)
    }

    const updateTitle = (value) => {
        let pageCopy = {...page};
        pageCopy.title = value

        setPage(pageCopy)
    }

    const updateSize = (value) => {
        let pageCopy = {...page};
        pageCopy.containerWidth = value

        setPage(pageCopy)
    }

    const updatePage = async () => {
        setMessage("Votre page a été sauvegardée !")
        setVariant("success")
        setTitle("Hey !")

        await publicPostApi("/pages/" + page.slug, "PUT", page).then(response => {
            if (!response.ok) {
                setVariant("danger")
                response.json().then(data =>
                    setMessage(data["hydra:description"])
                )
            }
        })

        setDisplayToast(true);
    }

    const getContentHtml = (lineId, colId) => {
        let lineToModify = {...page.template.find(line => lineId === line.id)}
        let colToModify = {...lineToModify.cols.find(col => colId === col.id)}

        return colToModify.contentHtml;
    }


    const savePage = async () => {
        setMessage("Votre page a été sauvegardée !")
        setVariant("success")
        setTitle("Hey !")

        let newId = 0;
        await publicPostApi("/pages", "POST", page).then(response => {
            if (!response.ok) {
                setVariant("danger")
                setTitle("Ya eu une erreur là...")
                response.json().then(data =>
                    setMessage(data["hydra:description"])
                )
            } else {
                response.json().then(data => {
                    setDisplayToast(true);
                    window.location.href = "/pages/" + data.slug
                })
            }
        })

        setDisplayToast(true);
    }

    const updateLineContent = (lineId, colId, content) => {
        let pageCopy = {...page};
        let templateCopy = [...pageCopy.template];

        let lineToModify = {...page.template.find(line => lineId === line.id)}
        let colToModify = {...lineToModify.cols.find(col => colId === col.id)}

        let colToModifyIndex = lineToModify.cols.findIndex(col => colId === col.id)
        let LineToModifyIndex = page.template.findIndex(line => line.id === lineId)

        if (typeof colToModify === "undefined") {
            return
        }

        colToModify.contentHtml = content;

        lineToModify.cols.splice(colToModifyIndex, 1, colToModify)

        if (LineToModifyIndex !== -1) {
            templateCopy.splice(LineToModifyIndex, 1, lineToModify)
        }

        pageCopy.template = templateCopy;
        setPage(pageCopy)
    }


    return (
        <PageContext.Provider value={{ page, updateContent, getContentHtml, updateLineContent, updateMediaContent, savePage, addLine, updateLine, removeLine, loadPage, updatePage, updateTitle, updateSize, preview, setPreview, initPage }}>
            {children}
        </PageContext.Provider>
    )
}