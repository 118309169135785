import React from 'react';
import {useContext, useEffect, useState} from "react";
import '../css/RowTemplate.css';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useSearchParams, Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import { teal } from '@mui/material/colors';
import {FamilyContext} from "./context/FamilyContext";
import {PageContext} from "./context/PageContext";
import PageWrapper from "./PageWrapper";
import {Box, FormControl, Paper, Stack, Step, StepLabel, Stepper, TextField, Tooltip, Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { styled } from '@mui/material/styles';
import Cm2cButton, {Cm2cLoadingButton} from "./Cm2cButton";
import {isEmail, isPhone} from "./Helper/Validator";
import Students from "./subscription/Students";
import Courses from "./subscription/Courses";
import Summary from "./subscription/Summary";
import Completed from "./subscription/Completed";
import Family from "./subscription/Family";
import {UserContext} from "./context/UserContext";
import {fetchApiPlatform, postApi, publicFetchApiPlatform, publicPostApi} from "./api/Content";
import {CourseContext} from "./context/CourseContext";
import {AlertContext} from "./context/AlertContext";
const steps = ['Famille', 'Éleve(s)', 'Cours', 'Récapitulatif'];

function AskResetPassword() {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    function sendRequest() {
        setLoading(true)
        publicPostApi("/reset-password/ask", "POST", {
            "email": email,
        }).then(response => {
            setLoading(false)
        });
    }

    return (
        <PageWrapper>
            <Grid2 container spacing={2} >
                <Grid2 md={6} xlOffset={4} xl={4} >
                    <Paper elevation={2}>
                        <Grid2 xs={12}>
                            <Typography variant={"h6"}>Demande de réinitialisation de mot de passe</Typography>
                        </Grid2>
                        <Grid2 xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <TextField
                                    type="email"
                                    required
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                    id="lastname-input" label="Email" variant="outlined" />
                            </FormControl>
                        </Grid2>
                        <Grid2 xs={12}>
                            <Cm2cLoadingButton onClick={() => sendRequest()}  loading={loading}>Valider ma demande</Cm2cLoadingButton>
                        </Grid2>
                    </Paper>
                </Grid2>
            </Grid2>
        </PageWrapper>
    );
}

export default AskResetPassword